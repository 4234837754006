import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getCall, startMeeting } from '../../utils/methods';
import { config } from '../../config';
import { Button, Divider, Paper, Rating, Tooltip } from '@mui/material';
import { underscoreToSpacedCamel } from '../../utils/text';
import { LiaIdCardSolid, LiaUsersSolid } from "react-icons/lia";
import { IoMdCopy } from 'react-icons/io';
import { bottomAlert } from '../../utils/toastAlerts';
import { MdOutlineTimer } from 'react-icons/md';
import { PiUsersFour } from 'react-icons/pi';
import { CiStar } from 'react-icons/ci';
import { IoTimeOutline } from 'react-icons/io5';
import { msToMinuteSec } from '../../utils/time';
import { FaEdit, FaShareSquare } from 'react-icons/fa';
import { ClientContext } from '../../context/ClientContext';
import { FaUserPen } from 'react-icons/fa6';
import "./meeting-details.scss";

function MeetingDetails() {
    const meetingId = useParams().id;
    const navigate = useNavigate();
    const { Client } = useContext(ClientContext);
    const [meetingData, setMeetingData] = useState();
    const [rows, setRows] = useState([]);

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        
        return new Intl.DateTimeFormat("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "2-digit"
        }).format(date);
    }

    const formatRunTime = (milliseconds) => {
        const hours = Math.floor(milliseconds / 3600000);
        const minutes = Math.floor((milliseconds % 3600000) / 60000);
        const seconds = Math.floor((milliseconds % 60000) / 1000);

        return `${hours}h ${minutes}m ${seconds}s`;
    }

    const copyToClipboard = (contentToCopy) => {
        // Create a temporary input element
        var tempInput = document.createElement("input");
        tempInput.setAttribute("type", "text");
        tempInput.setAttribute("value", contentToCopy);
        document.body.appendChild(tempInput);
    
        // Select the input element's text
        tempInput.select();
    
        // Copy the selected text to the clipboard
        document.execCommand("copy");
    
        // Remove the temporary input element
        document.body.removeChild(tempInput);
    
        bottomAlert("Successfully copied!");
    }

    const processParticipants  = () => {
        if(!meetingData) return;

        const { tokens, moderators, participants } = meetingData;

        const allMembers = new Set([
            ...moderators,
            ...participants,
            ...Object.keys(tokens)
        ]);

        const processedRows = Array.from(allMembers).map((member) => {
            const isModerator = moderators.includes(member);
            // const isParticipant = participants.includes(member);

            const tokenData = tokens[member] || {};
            const participantSessions = Object.values(tokenData.sessions || {});
            const startTime = participantSessions.length ? Math.min(...participantSessions.map(session => session.start_time)): 0;
            const endTime = participantSessions.length ? Math.max(...participantSessions.map(session => session.end_time)): 0;
            const meetingDuration = endTime && startTime ? endTime - startTime : tokenData.time_in_meeting;

            let feedbackScore = 0;
            let feedbackComments = "";
            
            if (meetingData.peoples.length > 0) {
                meetingData.participant_feedbacks.forEach(feedback => {
                    const person = meetingData.peoples.find(p => p.id === feedback.participant_id.trim());
                    if (person) {
                        const tokenEntry = Object.values(meetingData.tokens).find(
                            token => token.token === person.unique_field_value
                        );
                        if (tokenEntry && tokenEntry.token === tokenData.token) {
                            feedbackScore = feedback.feedback;
                            feedbackComments = feedback.feedback_comments || "";
                        }
                    }
                });
            } else {
                const feedbackData = meetingData.participant_feedbacks.find(
                    feedback => feedback.participant_id.trim() === member
                );
                
                if (feedbackData) {
                    feedbackScore = feedbackData.feedback;
                    feedbackComments = feedbackData.feedback_comments || "";
                }
            }
    
            return {
                name: member,
                role: isModerator ? "Moderator" : "Participant",
                attended: tokenData.attended ? "Attended" : "Missed",
                sessions: Object.keys(tokenData.sessions || {}).length || 0,
                timeInMeeting: tokenData.time_in_meeting || 0,
                participantSessions: participantSessions || [],
                meetingDuration: meetingDuration || 0,
                startTime: startTime || 0,
                endTime: endTime || 0,
                feedbackScore: feedbackScore || 0,
                feedbackCommnets: feedbackComments || ""
            };
        });
    
        setRows(processedRows);
    }

    useEffect(() => {
      getCall({
        url: config.url.MEETINGS,
        path: meetingId,
        onSuccess: (response) => {
            const totalFeedbacks = response.data.participant_feedbacks.length;
            const sumFeedbacks = response.data.participant_feedbacks.reduce((sum, entry) => sum + entry.feedback, 0);
            const averageFeedback = totalFeedbacks > 0 ? sumFeedbacks / totalFeedbacks : 0;

            setMeetingData({...response.data, average_feedback: averageFeedback});
        },
        onFailure: (error) => {
            console.log("Unable to fetch meeting details!")
        }
      })
    
      return () => {}
    }, [meetingId])

    useEffect(() => {
        processParticipants();

        // eslint-disable-next-line
    }, [meetingData]);
    const isButtonEnabled = () => {
        if (!Client?.attend_schedule_call_before) {
          return false;
        }
      
        if (!meetingData?.scheduled_time) {
          return false;
        }
      
        const callBeforeMinutes = Client.attend_schedule_call_before;
        const scheduledDate = new Date(meetingData.scheduled_time);
        const enableTime = new Date(scheduledDate.getTime() - callBeforeMinutes * 60 * 1000);
        const currentTime = new Date();
      
        return currentTime >= enableTime;
      };
      const buttonEnabled = isButtonEnabled();
    return meetingData && (
        <>
            <Paper sx={{mx:2, mb: 2}} className='meeting-details-container'>
                <div className='header'>
                    <h1>
                        {underscoreToSpacedCamel(meetingData.meeting_name)}
                        <Tooltip title={"Meeting Status"}>
                            <span className="attende-pill attended">{meetingData.meeting_status}</span>
                        </Tooltip>
                    </h1>
                    <div className='info-container'>
                        <div className='info'>
                            <p>{underscoreToSpacedCamel(meetingData.description)}</p>
                            <p>{formatDate(meetingData.scheduled_time)}</p>
                            <p>
                            {meetingData.scheduled_time 
                                ? new Date(meetingData.scheduled_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) 
                                : "-"}
                            </p>
                        </div>
                        <div className='quick-action'>
                            {meetingData.meeting_status !== "COMPLETED" && meetingData.meeting_status !== "CANCELLED" && (
                                <>
                                    <Tooltip title="Edit conference">
                                        <Button variant='contained' color='primary' disableElevation onClick={() => navigate(`/meetings/edit/${meetingData.id}`)}>
                                            <FaEdit/>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Copy public share link">
                                        <Button variant='contained' color='warning' disableElevation onClick={() => copyToClipboard(`${Client.dns_domain?.trim() || config.url.BASE}/actions/join_meeting/${meetingData.id}/ask`)}>
                                            <FaShareSquare/>
                                        </Button>
                                    </Tooltip>
                                    {meetingData.registration_enabled && (
                                        <Tooltip title="Copy registration link">
                                            <Button variant='contained' color='error' disableElevation onClick={() => copyToClipboard(`${Client.dns_domain?.trim() || config.url.BASE}/source/registration/${meetingData.id}`)}>
                                                <FaUserPen/>
                                            </Button>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className='stats'>
                    <Tooltip title={meetingData.id}>
                        <p onClick={() => copyToClipboard(meetingData.id)}>
                            <LiaIdCardSolid/> {meetingData.id} <IoMdCopy className='copy-icon' />
                        </p>
                    </Tooltip>
                    <div className='stats-1'>
                        <p><MdOutlineTimer /> {formatRunTime(meetingData.run_time)}</p>
                        <p><PiUsersFour /> {Object.keys(meetingData.tokens).length} Participants</p>
                    </div>
                    <div className='stats-2'>
                        <Tooltip title={"Participant Time"}> 
                            {meetingData.billing_cumulative_participant_time ? 
                                <p><IoTimeOutline /> {msToMinuteSec(meetingData.billing_cumulative_participant_time)} Participant Time</p> : 
                                <p><IoTimeOutline /> {"0"} Participant Time</p>
                            }
                        </Tooltip>
                        <Tooltip title={"Feedback Average"}><p> <CiStar /> {meetingData.average_feedback.toFixed(2)}</p></Tooltip>
                    </div>
                </div>
                
                {meetingData.meeting_status !== "COMPLETED" && meetingData.meeting_status !== "CANCELLED" && (
                    <div className='join-action'>
                        <Divider variant='middle'/>
                        <Tooltip title={meetingData.meeting_status === "IN_PROGRESS" ? "Join Conference" : "Start Conference"}>
                            <Button variant='contained' color='success' disableElevation 
                            style={{ pointerEvents: buttonEnabled ? "auto" : "none", opacity: buttonEnabled ? 1 : 0.5 }}
                                onClick={() => {
                                    startMeeting({
                                        meeting: meetingData,
                                        onSuccess: (response) => {
                                        navigate(`/meetings/live/${meetingData.id}`);
                                        },
                                    });
                                }}
                            >
                                {meetingData.meeting_status === "IN_PROGRESS" ? "Join Conference" : "Start Conference"}
                            </Button>
                        </Tooltip>
                    </div>
                )}
            </Paper>
            <Paper className='participants-detail-container'>
                <h2><LiaUsersSolid/> Participant</h2>
                <div className='table-container'>
                    <table id="participants-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Role</th>
                                <th>Status</th>
                                <th>Sessions</th>
                                <th>Time Spent</th>
                                <th>Timeline</th>
                                <th>Feedback</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((user, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="avatar-circle">{user.name.charAt(0)} </div>
                                            <span> {user.name}</span>
                                        </td>
                                        <td>
                                            <span className={`role-pill ${user.role.toLowerCase()}`}>
                                                {user.role}
                                            </span>
                                        </td>
                                        <td>
                                            <span className={`attende-pill ${user.attended.toLowerCase()}`}>
                                                {user.attended}
                                            </span>
                                        </td>
                                        <td>{user.sessions}</td>
                                        <td>{formatRunTime(user.timeInMeeting)}</td>
                                        <td>
                                            <div className="timeline-bar-container">
                                                {user.participantSessions.map((session, index) => {
                                                    const sessionStart = session.start_time;
                                                    const sessionEnd = session.end_time || user.endTime;
                                                    const sessionStartPercent = ((sessionStart - user.startTime) / user.meetingDuration) * 100;
                                                    const sessionEndPercent = ((sessionEnd - user.startTime) / user.meetingDuration) * 100;
                                                    const sessionWidthPercent = sessionEndPercent - sessionStartPercent;

                                                    return (
                                                        <div
                                                        key={index}
                                                        className="timeline-bar"
                                                        style={{
                                                            left: `${sessionStartPercent}%`,
                                                            width: `${sessionWidthPercent}%`
                                                        }}
                                                        ></div>
                                                    );
                                                })}
                                            </div>
                                        </td>
                                        <td>
                                            <Tooltip title={user.feedbackComments || "no comments"}>
                                                <span style={{ cursor: "pointer" }} className="participants-rating-feedback">
                                                <Rating
                                                    name="rating-feedback"
                                                    value={+user.feedbackScore || 0}
                                                    readOnly
                                                />
                                                </span>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Paper>
        </>
    )
}

export default MeetingDetails