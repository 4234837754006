import React, { useState, useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Fab } from "@mui/material";
import { Tooltip } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { FaFile, FaPaperclip } from "react-icons/fa6";
import { HiPlusSm } from "react-icons/hi";
import { postCall, putCall, uploadWhatsAppFiles } from "../../../utils/methods";
import { config } from "../../../config";
import { UserContext } from "../../../context/UserContext";
import { bottomAlert } from "../../../utils/toastAlerts";
import { checkAirtelFileTypeAndSize } from "../../../utils/CheckAirtelFileTypeAndSize";
import { ClientContext } from "../../../context/ClientContext";
import { SocketContext } from "../../../context/SocketContext";
import EmojisBoard from "../EmojisBoard/EmojisBoard";
import LoadingButton from "@mui/lab/LoadingButton";
import WhatsappTemplate from "../WhatsappTemplate/WhatsappTemplate";
// import { Popover, Typography } from "@mui/material";

import "./send-message.scss";
import { NotificationContext } from "../../../context/NotificationContext";

export default function SendWhatsAppMessage({
  id,
  leadData,
  setLeadData,
  // selectedLead,
  userNumber,
  handleScrollStable,
  sessionTimeLeft,
  checkHandoverStatus,
  handoverWhatsApp,
  controlePanelToggle,
  showControlPanel
}) {
  const [userResponse, setUserResponse] = useState("");
  const [userResponseType, setUserResponseType] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { User } = useContext(UserContext);
  const { Client } = useContext(ClientContext);
  const { Socket, setSocket } = useContext(SocketContext);
  const { Notification, setNotification } = useContext(NotificationContext);
  // const [anchor_menu, set_anchor_menu] = useState(null);
  // const navigate = useNavigate();

  let waSessionID;

  if (leadData?.length > 0) {
    waSessionID = leadData?.filter((msg) => msg.session_id !== "");
    waSessionID = waSessionID[waSessionID.length - 1]?.session_id || "";
  }

  const sendMessage = () => {
    if (files.length > 0) {
      setLoading(true);

      const mediaType = checkAirtelFileTypeAndSize(
        files[0].type,
        files[0].size
      );

      if (mediaType === "UNKNOWN") {
        setFiles([]);
        setLoading(false);
        bottomAlert("Selected file is not supported for sending!", "error");
        return;
      }

      uploadWhatsAppFiles({
        url: config.url.BASE,
        path: "/whatsapp/add_media",
        files: files,
        form: {
          sender_number: Client.whatsapp_number,
          media_type: mediaType,
          message_type: "SESSION_MESSAGE",
        },
        onSuccess: (response) => {
          addConversation({
            type: mediaType,
            id: response.data.id,
          });
        },
        onFailure: (error) => {
          setLoading(false);
          bottomAlert(
            "Unable to upload the document. " +
              ((error.response && error.response.data.detail) ||
                error.toString()),
            "warning"
          );
        },
      });
    } else {
      if (userResponse.trim()) {
        addConversation();
      }
    }
  };

  const addConversation = (isMedia = null) => {
    setLoading(true);

    postCall({
      path: `whatsapp/send_session_message`,
      data: {
        whatsapp_session_id: waSessionID,
        user_number: userNumber,
        sender_number: Client.whatsapp_number,
        message:
          isMedia !== null
            ? {}
            : {
                text: userResponse,
              },
        meeting_id: "",
        agent_id: User.id,
        people_id: id,
        media_attachment: isMedia !== null ? isMedia : {},
      },
      onSuccess: (response) => {
        setUserResponse("");
        setFiles([]);
        setLoading(false);
      },
      onFailure: (error) => {
        setLoading(false);
        bottomAlert(
          "Unable to post the message. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
  };

  const onFileUpload = (event, type) => {
    if (event.target.files && event.target.files.length > 0) {
      setFiles((files) => [...files, ...Array.from(event.target.files)]);
      setUserResponseType(type);
    }
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    handleScrollStable("bottom");
    // eslint-disable-next-line
  }, [leadData]);

  useEffect(() => {
    const data = Socket?.last_whatsapp_chat_message;

    if (
      data?.message_type === "whatsapp_chat_message" &&
      id === data.conversation.people_id
    ) {
      setLeadData((prevLeads) => [...prevLeads, data.conversation]);
    }

    // eslint-disable-next-line
  }, [Socket?.last_whatsapp_chat_message]);

  useEffect(() => {
    return () => {
      putCall({
        url: config.url.PEOPLE,
        path: `set_conversations_read/${id}`,
        onSuccess: (response) => {},
        onFailure: (error) => {
          console.log(error);
        },
      });

      const filteredNotifications = Notification?.whatsappNotification?.filter(
        (lead) =>
          lead?.notification_type ? lead?.people_id !== id : lead?.id !== id
      );

      setNotification((prev) => ({
        ...prev,
        whatsappNotification: filteredNotifications,
      }));

      setSocket((prev) => ({ ...prev, last_whatsapp_chat_message: null }));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="send-message-container">
      {userResponseType === "document" &&
        files.map((file, index) => {
          return (
            <div
              className="uploaded-doc-preview"
              key={"agent_document_preview_" + index}
            >
              <FaFile fontSize={18} />
              {file.name}
              <p className="remove-uploaded-doc-btn" onClick={removeFile}>
                <FaTimes />
              </p>
            </div>
          );
        })}
      <div
        className="send-message-wrapper"
        style={{
          opacity: checkHandoverStatus === false ? "0.5" : "1",
          filter: checkHandoverStatus === false ? "blur(2px)" : "unset",
        }}
      >
        <div className="action-button">
          <Fab size="small"
            style={{
              boxShadow: "none",
              background: "#02307D",
            }}
            // onClick={ (event) => set_anchor_menu(event.currentTarget) }
            onClick={controlePanelToggle}
            >
            <HiPlusSm
              style={{
                color: "#fff",
                transform: showControlPanel ? "rotate(135deg)": "rotate(0deg)",
                transition: "transform 0.5s",
              }}
            />
          </Fab>

          {/* popover modal */}
          {/* <Popover
                id={
                  Boolean(anchor_menu) ? "simple-popover" : undefined
                }
                open={Boolean(anchor_menu)}
                anchorEl={anchor_menu}
                onClose={() => set_anchor_menu(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography
                  style={{
                    margin: "10px 10px 5px 10px",
                    cursor: "pointer",
                    background: "#eee",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    set_anchor_menu(null);
                    navigate(
                  `/meetings/new/conference?participant=${selectedLead.unique_field_value}`
                )}
                }
                >
                  New Meeting
                </Typography>
                <Typography
                  style={{
                    margin: "10px 10px 5px 10px",
                    cursor: "pointer",
                    background: "#eee",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                  onClick={() => {set_anchor_menu(null);
                  navigate(
                  `/meetings/new/schedule?participant=${selectedLead.unique_field_value}`
                )}
                }
                >
                  Schedule Meeting
                </Typography>
              </Popover> */}
        </div>

        <input
          type="text"
          placeholder="Enter message"
          multiline="false"
          className="send-message-input"
          value={userResponse}
          onChange={(e) => setUserResponse(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              sendMessage();
            }
          }}
        />

        <div className="send-message-buttons-wrapper">
          <EmojisBoard
            addEmojiToInput={(emoji) => {
              setUserResponse((prevUserResponse) => prevUserResponse + emoji);
            }}
          />

          <Tooltip title="Upload files" arrow>
            <label htmlFor="upload-file">
              <div
                style={{
                  display: "flex",
                  opacity: sessionTimeLeft === 0 || loading ? "0.4" : "1",
                }}
              >
                <FaPaperclip />
                <input
                  type="file"
                  id="upload-file"
                  style={{ display: "none" }}
                  accept="*"
                  onChange={(e) => onFileUpload(e, "document")}
                  disabled={sessionTimeLeft === 0 || loading}
                />
              </div>
            </label>
          </Tooltip>

          <WhatsappTemplate id={id} userNumber={userNumber} />

          {checkHandoverStatus === true &&
          !(userResponse.trim() || files.length > 0) ? (
            <LoadingButton
              color="error"
              variant="contained"
              onClick={() => handoverWhatsApp(false)}
              size="small"
            >
              <span>Release</span>
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              onClick={() => sendMessage()}
              size="small"
              loading={loading}
              disabled={
                sessionTimeLeft === 0 ||
                !(userResponse.trim() || files.length > 0)
              }
            >
              <span>Send</span>
            </LoadingButton>
          )}
        </div>
      </div>
      {checkHandoverStatus === false && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            placeContent: "center",
            placeItems: "center",
          }}
        >
          <LoadingButton
            color="warning"
            variant="contained"
            onClick={() => handoverWhatsApp(true)}
            size="small"
          >
            <span>Take Over</span>
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
