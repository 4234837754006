import React, { useContext, useEffect, useState, useCallback } from "react";
import "./custom-calendar.scss";
import { MdChevronLeft, MdChevronRight, MdEditCalendar } from "react-icons/md";
import CountdownTimer from "../../utils/countdown";
import { PiShareFatFill, PiSpinner } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { GiCoffeeCup } from "react-icons/gi";
import { startMeeting } from "../../utils/methods";
import { config } from "../../config";
import { topAlert } from "../../utils/toastAlerts";
import { ClientContext } from "../../context/ClientContext";

function CustomCalendar({
  meetings,
  selectedDate,
  setSelectedDate,
  allMeetings,
}) {
  const isDesktop = window.matchMedia("(min-width: 768px)").matches;

  const { Client } = useContext(ClientContext);
  const [currentMonth, setCurrentMonth] = useState(new Date(selectedDate));
  const [view, setView] = useState(!isDesktop); // 'week'(true) or 'month'(false)
  const [startTouchY, setStartTouchY] = useState(0);
  const [enabledMeetings, setEnabledMeetings] = useState({});
  const [loadingMeetings, setLoadingMeetings] = useState({});
  const navigate = useNavigate();

  const renderCalendar = () => {
    // Get current date
    // const currentDate = new Date();
    const currentDate = currentMonth;

    if (view) {
      // Get the start of the current week (Sunday)
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

      return renderCalendarTable(startOfWeek, 1);
    } else {
      // Get the first day of the current month
      const startOfMonth = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        1
      );
      startOfMonth.setDate(startOfMonth.getDate() - startOfMonth.getDay()); // Adjust to the start of the week

      return renderCalendarTable(startOfMonth, 5);
    }
  };

  const renderCalendarTable = (startDate, numRows) => {
    const tableRows = [];

    // Create the header row
    const headerRow = (
      <tr key="header">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <th key={day}>{day}</th>
        ))}
      </tr>
    );

    tableRows.push(headerRow);

    // Create the calendar rows
    for (let i = 0; i < numRows; i++) {
      const rowCells = [];

      for (let j = 0; j < 7; j++) {
        const cellDate = new Date(startDate);
        cellDate.setDate(startDate.getDate() + i * 7 + j);
        const day = cellDate.getDate();

        const cell = (
          <td
            key={day}
            onClick={() => {
              const month = (cellDate.getMonth() + 1).toString();
              const day = cellDate.getDate().toString();
              const year = cellDate.getFullYear().toString();
              const formattedDate = `${month}/${day}/${year}`; // e.g. 1/17/2024
              setCurrentMonth(cellDate);
              setSelectedDate(formattedDate);
            }}
          >
            <span
              className={`day-cell ${
                cellDate.getMonth() !== currentMonth.getMonth() ? "fade " : ""
              }${
                cellDate.toDateString() === new Date().toDateString()
                  ? "today "
                  : ""
              }${
                cellDate.toDateString() === currentMonth.toDateString()
                  ? "active "
                  : ""
              }`}
            >
              {day < 10 ? "\u00a0" : ""}
              {day}
              {day < 10 ? "\u00a0" : ""}
            </span>

            {allMeetings.map((meeting) => {
              const month = (cellDate.getMonth() + 1).toString();
              const day = cellDate.getDate().toString();
              const year = cellDate.getFullYear().toString();
              const formattedDate = `${month}/${day}/${year}`;

              if (
                meeting.date === formattedDate &&
                cellDate.toDateString() !== new Date().toDateString()
              ) {
                return (
                  <span className="notification-dot" key={meeting.date}></span>
                );
              }
              return "";
            })}
          </td>
        );

        rowCells.push(cell);
      }

      const row = <tr key={`row-${i}`}>{rowCells}</tr>;
      tableRows.push(row);
    }

    return <tbody>{tableRows}</tbody>;
  };

  const prev = () => {
    setCurrentMonth((prevMonth) => {
      const newMonth = new Date(prevMonth);
      newMonth.setMonth(prevMonth.getMonth() - 1);

      return newMonth;
    });
  };

  const next = () => {
    setCurrentMonth((prevMonth) => {
      const newMonth = new Date(prevMonth);
      newMonth.setMonth(prevMonth.getMonth() + 1);

      return newMonth;
    });
  };

  const handleTouchStart = (e) => {
    setStartTouchY(e.touches[0].clientY);
  };

  const handleTouchEnd = (e) => {
    const endTouchY = e.changedTouches[0].clientY;
    const deltaY = endTouchY - startTouchY;

    const swipeThreshold = 20;

    if (deltaY > swipeThreshold) {
      // console.log("Swipe down!");
      setView(false);
    } else if (deltaY < -swipeThreshold) {
      // console.log("Swipe up!");
      setView(true);
    }
  };

  const copyToClipboard = (meetingId) => {
    const customDomain = Client.dns_domain?.trim();
    
    // Get the URL that you want to copy
    const urlToCopy = `${customDomain || config.url.BASE}/actions/join_meeting/${meetingId}/ask`;

    // Create a temporary input element
    var tempInput = document.createElement("input");
    tempInput.setAttribute("type", "text");
    tempInput.setAttribute("value", urlToCopy);
    document.body.appendChild(tempInput);

    // Select the input element's text
    tempInput.select();

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    topAlert("Url Copied!");
  }
  // const isButtonEnabled = (meetings) => {
  //   if (!Client?.attend_schedule_call_before || !meetings?.scheduled_time) {
  //     return false;
  //   }
  //   const callBeforeMinutes = Client.attend_schedule_call_before;
  //   const scheduledDate = new Date(meetings.scheduled_time);
  //   const enableTime = new Date(scheduledDate.getTime() - callBeforeMinutes * 60 * 1000);
  //   const currentTime = new Date();
  //   return currentTime >= enableTime;
  // };
  //  useEffect(() => {
  //   const interval = setInterval(() => {
  //     const updatedEnabledMeetings = {};
  //     meetings.forEach((meeting) => {
  //       if (isButtonEnabled(meeting)) {
  //         updatedEnabledMeetings[meeting.id] = true;
  //       }
  //     });
  //     setEnabledMeetings(updatedEnabledMeetings);
  //   }, 1000); // Check every second

  //   return () => clearInterval(interval); // Cleanup interval on component unmount
  // }, [meetings]);

  const isButtonEnabled = useCallback((meeting) => {
    if (!Client?.attend_schedule_call_before || !meeting?.scheduled_time) {
      return false;
    }
    const callBeforeMinutes = Client.attend_schedule_call_before;
    const scheduledDate = new Date(meeting.scheduled_time);
    const enableTime = new Date(scheduledDate.getTime() - callBeforeMinutes * 60 * 1000);
    const currentTime = new Date();
    return currentTime >= enableTime;
  }, [Client?.attend_schedule_call_before]);

  
  useEffect(() => {
    const interval = setInterval(() => {
      const updatedEnabledMeetings = {};
      meetings.forEach((meeting) => {
        if (isButtonEnabled(meeting)) {
          updatedEnabledMeetings[meeting.id] = true;
        }
      });
      setEnabledMeetings(updatedEnabledMeetings);
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, [meetings, isButtonEnabled]); // Now eslint will not complain

  
  return (
    <div className="sa-dashboard-lite-upcoming-schedule">
      <div
        className={`sa-dashboard-lite-custom-calendar ${!view ? "expanded" : "closed"
          }`}
      >
        <div className="header">
          <span className="header-active-date">
            <span className="day">{currentMonth.getDate()}</span>

            <span className="month-year">
              <span>
                {currentMonth.toLocaleString("en-us", { month: "long" })}
              </span>
              <span>{currentMonth.getFullYear()}</span>
            </span>
          </span>

          <span className="prev-next">
            <span onClick={prev}>
              <MdChevronLeft />
            </span>
            <span onClick={next}>
              <MdChevronRight />
            </span>
          </span>
        </div>

        <table cellSpacing={8}>{renderCalendar()}</table>
      </div>
      <div
        className={`sa-dashbaord-lite-upcoming-task-card ${
          !view ? "expanded" : "closed"
        }`}
      >
        <span
          className="toggle-card-button"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onClick={() => setView(!view)}
        ></span>

        {meetings?.length === 0 ? (
          <div className="saleassist-upcoming-meeting-lite">
            <div className="no-upcoming-meeting-placeholder">
              <GiCoffeeCup />
              <p>
                No meetings found,<span>Grab a coffee!</span>
              </p>
            </div>
          </div>
        ) : (
          <div className="list-content">
            {meetings?.map((meeting) => {
              const buttonEnabled = enabledMeetings[meeting.id] || false;
              return (
                <div key={meeting.id} className="meeting-card">
                  <span className="card-header">
                    <span className="title">{meeting.meeting_name}</span>
                    <span
                      className="dashboard-meeting-details-quick-action-btn"
                      onClick={() => {
                      setLoadingMeetings((prev) => ({ ...prev, [meeting.id]: true }));
                        
                        startMeeting({
                          meeting: meeting,
                          onSuccess: (response) => {
                          setLoadingMeetings((prev) => ({ ...prev, [meeting.id]: false }));
                            navigate(`/meetings/live/${meeting.id}`);
                          },
                        });
                      }}
                      style={{ pointerEvents: buttonEnabled ? "auto" : "none", opacity: buttonEnabled ? 1 : 0.5 }}
                    >
                      {loadingMeetings?.[meeting.id] ?  (
                    <>
                      <PiSpinner spin style={{ marginRight: 8 }} className="spin-animation-start" />
                      Joining...
                    </>
                        ) : (
                          ["SCHEDULED", "WAITING"].includes(meeting.meeting_status)
                            ? "START"
                            : ["STARTED", "IN_PROGRESS"].includes(meeting.meeting_status)
                              ? "RE-JOIN"
                              : ""
                        )}
                    </span>
                  </span>
                  <span className="time">
                      {meeting.scheduled_time
                        ? new Date(meeting.scheduled_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                        : "-"}
                    </span>
                  <span className="description">{meeting.description}</span>

                  <span style={{ marginTop: 2 }} className="time-des">
                    <CountdownTimer
                      targetTime={meeting.time}
                      customText={{
                        ongoing: "Your meeting will start in ",
                        expired: "Your meeting occurred "
                      }}
                    />
                  </span>
                  <div className="action-buttons-main">
                    
                    <div className="action-buttons">
                      <span onClick={() => copyToClipboard(meeting.id)}>
                        <PiShareFatFill />
                      </span>
                    </div>
                  </div>
                  <div onClick={() => navigate(`/meetings/view/${meeting.id}`)} className="reschedule-button">
                    <MdEditCalendar />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomCalendar;
