import { alertMessage } from "./alertMessage";

export async function getDevices(AgoraRTC) {
  try {
    // To get Video input devices
    if (this.message_type === "video_call") {
      await AgoraRTC.getDevices();

      this.videoInputDevices = await AgoraRTC.getCameras();

      // creating and adding html element for video input options
      const videoInputDeviceSettingEl = document.getElementById(
        "videoInputDeviceSetting"
      );
      let optionsEl = "";
      // eslint-disable-next-line
      this.videoInputDevices.map((device) => {
        optionsEl += `<option value="${device.label}">${
          device.label.length > 24
            ? device.label.slice(0, 21) + "..."
            : device.label
        }</option>`;
      });
      videoInputDeviceSettingEl.innerHTML += `<select>${optionsEl}</select>`;
    }

    if (this.videoInputDevices && this.videoInputDevices.length > 1) {
      document.getElementById("flipCamera").style.display = "flex";
    }

    // To get Audio input devices
    this.audioInputDevices = await AgoraRTC.getMicrophones();

    // creating and adding html element for audio input options
    const audioInputDeviceSettingEl = document.getElementById(
      "audioInputDeviceSetting"
    );
    let optionsEl = "";
    // eslint-disable-next-line
    this.audioInputDevices.map((device) => {
      optionsEl += `<option value="${device.label}">${
        device.label.length > 24
          ? device.label.slice(0, 21) + "..."
          : device.label
      }</option>`;
    });
    audioInputDeviceSettingEl.innerHTML += `<select>${optionsEl}</select>`;
  } catch (error) {
    alertMessage("Devices Error~" + error.message);
    console.error("Devices Error:", error.message);
  }
}
