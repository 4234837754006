import React, { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
import { GiCoffeeCup } from "react-icons/gi";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { PiPhoneCallFill } from "react-icons/pi";

import { getCall } from "../../utils/methods";
import { config } from "../../config";
import { bottomAlert } from "../../utils/toastAlerts";
import { underscoreToSpacedCamel } from "../../utils/text";
import { msToLongDateString } from "../../utils/time";
import "./calls-queue.scss";

function ActiveRequestsQueue() {
  const [isLoading, setIsLoading] = useState(true);
  const [assignedRequests, setAssignedRequests] = useState([]);

  const fetchAssignedRequests = () => {
    getCall({
      url: config.url.MEETINGS,
      path: "/active/1/10",
      onSuccess: (response) => {
        setAssignedRequests(response.data.records);
        setIsLoading(false);
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to load assigned requests. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchAssignedRequests();
  }, []);

  if (isLoading) {
    return (
      <div className="saleassist-meeting-lite-loader-wrapper">
        <Bars
          height="60"
          width="60"
          color="#959595"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        Loading...
      </div>
    );
  }

  return (
    <div className="active-requests-queue-container" style={{padding:"10px"}}>
      <h1 className="active-requests-queue-title">Active Requests Queue</h1>
      <div>
        {assignedRequests?.length === 0 ? (
          <div className="saleassist-upcoming-meeting-lite">
            <div className="no-upcoming-meeting-placeholder">
              <GiCoffeeCup />
              <p>
                No active request found,<span>Grab a coffee!</span>
              </p>
            </div>
          </div>
        ) : (
          <>
            {assignedRequests?.map((request) => {
              return (
                <div className="active-requests-queue-wrapper">
                  <p className="active-requests-queue-text">
                    <strong>Meeting Type: </strong>
                    {underscoreToSpacedCamel(request.meeting_type)}
                  </p>
                  <p className="active-requests-queue-text">
                    Scheduled at: {msToLongDateString(request.scheduled_time)}
                  </p>
                  <p
                    className="active-requests-queue-text"
                    style={{ marginBottom: "12px" }}
                  >
                    Meeting Link:{" "}
                    <Link to={`/meetings/live/${request.id}`} target="_blank">
                      {`${config.url.BASE}/meetings/live/${request.id}`}
                    </Link>
                  </p>
                  <Link to={`/meetings/live/${request.id}`}>
                    <Button
                      color="success"
                      variant="contained"
                      sx={{ textTransform: "initial" }}
                    >
                      Attend meeting
                      <PiPhoneCallFill
                        style={{ marginLeft: "2px", marginTop: "2px" }}
                      />
                    </Button>
                  </Link>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default ActiveRequestsQueue;
