import React, { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
import { getCall } from "../../utils/methods";
import { config } from "../../config";
import { bottomAlert } from "../../utils/toastAlerts";
import CustomCalendar from "../CustomCalendar/CustomCalendar";
import "./upcoming-meeting-lite.scss";

function UpcomingMeetingLite() {
  const currentDate = new Date();
  const month = (currentDate.getMonth() + 1).toString();
  const day = currentDate.getDate().toString();
  const year = currentDate.getFullYear().toString();
  const formattedDate = `${month}/${day}/${year}`;

  const [isLoading, setIsLoading] = useState(true);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [selectedDate, setSelectedDate] = useState(formattedDate);

  const fetchUpcomingMeetings = () => {
    getCall({
      url: config.url.MEETINGS,
      path: "upcoming/all/" + 1 + "/" + 100,
      onSuccess: (response) => {
        const meetings = response.data.records?.filter(
          (meeting) =>
            meeting.meeting_status === "SCHEDULED" ||
            meeting.meeting_status === "WAITING" ||
            meeting.meeting_status === "IN_PROGRESS"
        );
        setUpcomingMeetings(meetings);
        setIsLoading(false);
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to load your meetings list. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchUpcomingMeetings();
  }, []);

  if (isLoading) {
    return (
      <div className="saleassist-meeting-lite-loader-wrapper">
        <Bars
          height="60"
          width="60"
          color="#959595"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        Loading...
      </div>
    );
  }

  const selectedDateMeetings = upcomingMeetings?.filter(
    (meeting) => meeting.date === selectedDate
  );

  return (
    <>
      <h1 className="upcoming-meeting-heading">Upcoming Meetings</h1>
      <CustomCalendar
        meetings={selectedDateMeetings}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        allMeetings={upcomingMeetings.filter(
          (obj, index, self) =>
            index === self.findIndex((o) => o.date === obj.date)
        )}
      />
    </>
  );
}

export default UpcomingMeetingLite;
