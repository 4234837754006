import React, { useState } from "react";
import { Tooltip, Rating } from "@mui/material";
import {
  FaCommentAlt,
  FaCalendarAlt,
  FaDownload,
  FaCheckCircle,
  FaExclamationCircle,
  FaDollarSign,
  FaFileAudio,
  FaFileVideo,
  FaFileSignature,
} from "react-icons/fa";
import {
  FaPhone,
  FaPhoneSlash,
  FaPhoneFlip,
  FaMessage,
  FaComment,
  FaPenToSquare,
} from "react-icons/fa6";
import {
  BsCameraReelsFill,
  BsCameraVideoFill,
  BsCameraVideoOffFill,
} from "react-icons/bs";
import { Modal, Box, Button, Typography, Backdrop, Fade } from "@mui/material";
import { SiWhatsapp } from "react-icons/si";
import { MdDownload, MdOutlineCopyAll } from "react-icons/md";
import { RiFileUserFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { underscoreToSpacedCamel } from "../../../utils/text";
import { getCall } from "../../../utils/methods";
import { config } from "../../../config";
import { msToLongDateString, msToTimeString } from "../../../utils/time";
import { bottomAlert } from "../../../utils/toastAlerts";
import { MessageBox } from "react-chat-elements";
import { saveAs } from "file-saver";
import { identifyMessageType } from "../../../utils/IdentifyMessageType";
// import "react-chat-elements/dist/main.css";
import { BiPurchaseTagAlt } from "react-icons/bi";

import "./message-box-wrapper.scss";

const isDictionary = (object) => {
  return object && object.constructor === Object;
};

export default function MessageBoxWrapper({
  item,
  isReceiver,
  hideTime,
  // hideUser,
  isModeratedChat,
  isWhatsapp,
  // sessionTimeLeft,
}) {
  const [userDetails, setUserDetails] = useState({})
  const [open, setOpen] = useState(false);
  const iconColor = item.meeting_id ? "#494949" : "#f00";
  const extractProductUrl = (url) => {
    try {
      const urlParams = new URLSearchParams(new URL(url).search);
      return decodeURIComponent(urlParams.get("product_url"));
    } catch (error) {
      return null;
    }
  };

  const fetchForm = (form_id) => {
    getCall({
      url: config.url.FORMS,
      path: form_id,
      onSuccess: (response) => {
        setUserDetails(response.data);
        console.log(response.data, 'object')
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to load the form. " +
          ((error.response && error.response.data.detail) ||
            error.toString()),
          "warning"
        );
      },
    });
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "0",
    borderRadius: "8px",
    overflow: "hidden",
    outline: "none"
  };
  return (
    <div
      className="msg-div"
      style={{
        placeItems: !isReceiver ? "end" : "start",
        padding: "0 10px",
      }}
    >
      {[
        "bot_response",
        "chat_request",
        "chat_response",
        "live_message",
      ].includes(item.message_type) && (
          <MessageBox
            notch={false}
            position={isReceiver ? "left" : "right"}
            type={"text"}
            text={item.message_content}
            status={null}
          />
        )}
      {["text_message"].includes(item.message_type) && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          text={
            <>
              {/* rendering message content dangerously because it can be simple string or html tag as well. */}
              <span
                dangerouslySetInnerHTML={{
                  __html: item.message_content,
                }}
              />
            </>
          }
          status={null}
        />
      )}
      {["text_feedback"].includes(item.message_type) && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          text={
            <>
              <span>
                <FaCommentAlt /> {underscoreToSpacedCamel(item.message_type)}
                {": "}
              </span>
              <span>{item.message_content}</span>
            </>
          }
          status={null}
        />
      )}

      {["payment", "refund"].includes(item.message_type) && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          text={
            <>
              <span>
                <FaDollarSign /> {underscoreToSpacedCamel(item.message_type)}
                {": "}
              </span>
              <span>{item.message_content}</span>
            </>
          }
          status={null}
        />
      )}

      {["video_call", "audio_call", "outbound_call"].includes(
        item.message_type
      ) &&
        isReceiver && (
          <MessageBox
            notch={false}
            position={isReceiver ? "left" : "right"}
            type={"text"}
            text={
              <>
                {item.message_type === "video_call" ? (
                  item.meeting_id ? (
                    <BsCameraVideoFill color={iconColor} />
                  ) : (
                    <BsCameraVideoOffFill color={iconColor} style={{marginRight:"5px"}}/>
                  )
                ) : item.message_type === "audio_call" ? (
                  item.meeting_id ? (
                    <FaPhone color={iconColor} />
                  ) : (
                    <FaPhoneSlash color={iconColor} />
                  )
                ) : (
                  <FaPhoneFlip color={iconColor} />
                )}
                {item.meeting_id ? (
                  <>
                    <span
                      className="text-primary"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        marginLeft: "5px"
                      }}
                      onClick={() => {
                        // this.props.history.push(
                        //   "/workspace/meetings/view/" +
                        //     item.message_content
                        // );
                      }}
                    >
                      {underscoreToSpacedCamel(item.message_type)}
                    </span>
                    {item.meta[0] ? (
                      <>
                        {" - "}
                        {"attended by "}
                        <span style={{ color: "rgba(0,0,0,0.5)",marginTop: "5px", display: "inline-block" }}>
                          {item.meta[0] && item.meta[0].moderators
                            ? item.meta[0].moderators.join(", ")
                            : "NA"}
                        </span>
                      </>
                    ) : (
                      "Missing metadata"
                    )}
                  </>
                ) : (
                  `Missed ${underscoreToSpacedCamel(item.message_type)}`
                )}
              </>
            }
            status={null}
          />
        )}

      {["schedule_meeting"].includes(item.message_type) && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          text={
            <>
              <FaCalendarAlt />{" "}
              {/* if item.meta[0].meeting_type === "store_visit" then text format will be: Walk-in Appointment booked with example@mail.com on Sep 25, 2023 10:30 AM */}
              {/* else: Schedule meeting with example@mail.com on Sep 25, 2023 10:30 AM */}
              <span
                className="text-primary"
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  // this.props.history.push(
                  //   "/workspace/meetings/view/" + item.message_content
                  // );
                }}
              >
                {item.meta?.[0]?.meeting_type === "store_visit"
                  ? "Walk-in Appointment"
                  : underscoreToSpacedCamel(item.message_type)}
              </span>
              {item.meta?.[0]?.meeting_type === "store_visit" && " booked "}
              {item.meta[0] ? (
                <>
                  {" "}
                  {"with "}
                  <span style={{ color: "rgba(0,0,0,0.5)" }}>
                    {item.meta[0] && item.meta[0].moderators
                      ? item.meta[0].moderators.join(", ")
                      : "NA"}
                  </span>
                  {" on " + msToLongDateString(item.meta[0].scheduled_time)}
                </>
              ) : (
                "Missing metadata"
              )}
            </>
          }
          status={null}
        />
      )}

      {item.message_type === "image" && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          text={
            <>
              <img
                src={item.message_content}
                alt=""
                style={{
                  width: "auto",
                  height: "280px",
                  maxWidth: "-webkit-fill-available",
                  borderRadius: "5px",
                }}
              />
            </>
          }
          status={null}
        />
      )}

      {item.message_type === "meeting_snap" && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          text={
            <>
              <img
                src={item.message_content}
                alt=""
                style={{
                  width: "auto",
                  height: "280px",
                  maxWidth: "-webkit-fill-available",
                  borderRadius: "5px",
                }}
              />
            </>
          }
          status={null}
        />
      )}

      {["audio_feedback", "voice_message"].includes(item.message_type) && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          text={
            <>
              {item.message_type === "voice_message" ? (
                <FaComment />
              ) : (
                <FaFileAudio />
              )}{" "}
              {underscoreToSpacedCamel(item.message_type)}
              <br />
              <audio
                src={item.message_content}
                controls
                autoPlay={false}
                style={{
                  height: "40px",
                  width: "revert",
                  maxWidth: "-webkit-fill-available",
                  borderRadius: "5px",
                  background: "#f1f3f4",
                }}
              >
                Your browser does not support the audio element.
              </audio>
            </>
          }
          status={null}
        />
      )}

      {["video_feedback", "video_message"].includes(item.message_type) && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          text={
            <>
              =
              {item.message_type === "video_message" ? (
                <BsCameraReelsFill />
              ) : (
                <FaFileVideo />
              )}{" "}
              {underscoreToSpacedCamel(item.message_type)}
              <br />
              <video
                src={item.message_content + "#t=0.01"}
                controls
                autoPlay={false}
                style={{
                  width: "auto",
                  height: "280px",
                  maxWidth: "-webkit-fill-available",
                  borderRadius: "5px",
                }}
                poster={"https://static.saleassist.ai/gifs/loader.gif"}
              >
                Your browser does not support the video element.
              </video>
            </>
          }
          status={null}
        />
      )}

      {["feedback_form", "agent_feedback_form"].includes(item.message_type) && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          text={
            <>
              {item.message_type === "agent_feedback_form" ? (
                <RiFileUserFill />
              ) : (
                <FaFileSignature />
              )}{" "}
              {underscoreToSpacedCamel(item.message_type)}
              <div
                className="p-1 slick-slider-sm mx-auto"
                style={{ fontSize: "14px" }}
              >
                {item.meta &&
                  isDictionary(item.meta[0]) &&
                  Object.keys(item.meta[0]).map((key, index) => {
                    const name = key;
                    const value = item.meta[0][key];
                    return name === "star_rating" ? (
                      <Rating
                        key={name + value}
                        name="rating-feedback"
                        value={+value}
                        readOnly
                      />
                    ) : (
                      <div key={name + value} className="page-title-subheading">
                        <div
                          style={{
                            minWidth: "130px",
                            fontStyle: "italic",
                            display: "inline-block",
                          }}
                        >
                          {name}:{" "}
                        </div>
                        <div
                          style={{
                            minWidth: "200px",
                            maxWidth: "70%",
                            display: "inline-block",
                          }}
                          className="text-secondary"
                        >
                          {value}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          }
          status={null}
        />
      )}

      {item.message_type === "note" && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          className={"follow-up-notes__background"}
          text={<>{item.message_content}</>}
          status={null}
        />
      )}

      {(item.message_type === "lead_purchase_status_change" ||
        item.message_type === "lead_config_status_change" ||
        item.message_type === "lead_config_type_change") && (
          <>
            <MessageBox
              notch={false}
              position="right"
              type={"text"}
              className={item.message_type === "lead_purchase_status_change" ? "lead_conversion_tracking_msg" : "lead_status_type_tracking_msg"}      //css for this in message-box-wrapper.scss.
              text={<>
                {item.message_type === "lead_purchase_status_change" && <BiPurchaseTagAlt style={{ fontSize: "20px" }} />}
                {" "} {item.message_content}
              </>}
              status={null}
            />
          </>
        )}

      {item.message_type === "document" && (
        <div
          className={`document_message_wrapper ${item.meta.length > 1 ? "multiple-document" : ""
            }`}
        >
          {item.meta.map((document, index) => (
            <MessageBox
              key={`${document.name}-${index}`}
              notch={false}
              position={isReceiver ? "left" : "right"}
              className={"document_message"}
              type={identifyMessageType(document.attachment_url)}
              data={
                identifyMessageType(document.attachment_url) === "photo"
                  ? {
                    uri: document.attachment_url,
                  }
                  : identifyMessageType(document.attachment_url) === "video"
                    ? {
                      videoURL: document.attachment_url,
                      status: {
                        click: true,
                        download: true,
                      },
                    }
                    : identifyMessageType(document.attachment_url) === "audio"
                      ? {
                        audioURL: document.attachment_url,
                      }
                      : identifyMessageType(document.attachment_url) === "file"
                        ? {
                          uri: document.attachment_url,
                          status: {
                            click: true,
                            download: true,
                          },
                        }
                        : {}
              }
              text={
                identifyMessageType(document.attachment_url) === "text" ? (
                  <div>
                    <span>{document.attachment_url}</span>
                  </div>
                ) : (
                  <>
                    {identifyMessageType(document.attachment_url) ===
                      "file" && (
                        <span className="chat-file-title-text">
                          document
                          <span>{document.attachment_url.split(".").pop()}</span>
                        </span>
                      )}
                    <div
                      className="chat-file-download-icon"
                      onClick={() =>
                        saveAs(document.attachment_url, "download")
                      }
                    >
                      <MdDownload />
                    </div>
                  </>
                )
              }
              status={null}
            />
          ))}
          {item.message_content && (
            <MessageBox
              notch={false}
              position={isReceiver ? "left" : "right"}
              type={"text"}
              text={item.message_content}
              status={null}
            />
          )}
        </div>
      )}

      {item.message_type === "url" && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          type={"text"}
          text={
            <Link
              to={{ pathname: item.message_content }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.content}
            </Link>
          }
          status={this.props.is_receiver ? "read" : "sent"}
        />
      )}

      {["whatsapp_message"].includes(item.message_type) && (
        <MessageBox
          notch={false}
          position={isReceiver ? "left" : "right"}
          className={"whatsapp_message"}
          type={identifyMessageType(item.message_content)}
          data={
            identifyMessageType(item.message_content) === "photo"
              ? {
                uri: item.message_content,
              }
              : identifyMessageType(item.message_content) === "video"
                ? {
                  videoURL: item.message_content,
                  status: {
                    click: true,
                    download: true,
                  },
                }
                : identifyMessageType(item.message_content) === "audio"
                  ? {
                    audioURL: item.message_content,
                  }
                  : identifyMessageType(item.message_content) === "file"
                    ? {
                      uri: item.message_content,
                      status: {
                        click: true,
                        download: true,
                      },
                    }
                    : {}
          }
          text={
            identifyMessageType(item.message_content) === "text" ? (
              <div>
                <span>{item.message_content}</span>
              </div>
            ) : (
              <>
                {identifyMessageType(item.message_content) === "file" && (
                  <span className="chat-file-title-text">
                    document
                    <span>{item.message_content.split(".").pop()}</span>
                  </span>
                )}
                <div
                  className="chat-file-download-icon"
                  onClick={() => saveAs(item.message_content, "download")}
                >
                  <MdDownload />
                </div>
              </>
            )
          }
          status={null}
        />
      )}

      {/* {!hideTime && ( */}
      <div
        className="msg-div-info"
        style={{
          textAlign: isReceiver ? "start" : "end",
        }}
      >
        {["text_message"].includes(item.message_type) && (
          <span>
            <FaMessage />
            {" Text | "}
          </span>
        )}
        {item.message_type === "note" && (
          <span>
            <FaPenToSquare />
            {" Follow-up Notes | "}
          </span>
        )}
        {item.message_type === "document" && (
          <span>
            <FaDownload />
            {" Document | "}
          </span>
        )}
        {item.message_type === "whatsapp_message" && !isWhatsapp && (
          <span>
            <SiWhatsapp />
            {" | "}
          </span>
        )}

        {item.message_type === "lead_purchase_status_change" && (
          <span>
            {" Conversion Tracking Message "}
          </span>
        )}
        {(item.message_type === "lead_config_status_change" || item.message_type === "lead_config_type_change") && (
          <span>
            {" Lead Status/Type changed "}
          </span>
        )}

        {/* {!hideUser && ( */}
        <>
          {!(item.message_type === "lead_purchase_status_change" ||
            item.message_type === "lead_config_status_change" ||
            item.message_type === "lead_config_type_change") &&
            <>
              <Tooltip
                title={
                  item.form_id &&
                  item.message_from === item.people_id &&
                  "Click to view the form"
                }
              >
                <span
                  onClick={() => {
                    item.form_id &&
                      item.message_from === item.people_id &&
                      fetchForm(item.form_id);
                    handleOpen();
                  }}
                  style={{
                    cursor:
                      item.form_id &&
                      item.message_from === item.people_id &&
                      "pointer",
                    textDecoration:
                      item.form_id &&
                      item.message_from === item.people_id &&
                      "underline",
                  }}
                >
                  {isReceiver ? item.unique_field_value : item.message_from}
                </span>
              </Tooltip>{" "}
            </>
          }
          |{" "}
        </>
        {/* )} */}

        {/* {!hideTime &&  */}
        <span>{msToTimeString(item.created_on)}</span>
        {/* } */}

        {!hideTime && isReceiver && (() => {
          const productUrl = extractProductUrl(item.source_referrer);

          return (
            <>
              <Tooltip style={{ cursor: "pointer" }} title={item.source_referrer}>
                <span>
                  {" "}
                  |{" "}
                  <span
                    onClick={() => {
                      navigator.clipboard.writeText(item.source_referrer);
                      bottomAlert("Copied to Clipboard", "info");
                    }}
                  >
                    <MdOutlineCopyAll />
                    Source
                  </span>
                </span>
              </Tooltip>

              {productUrl && productUrl !== "null" &&
                <Tooltip style={{ cursor: "pointer" }} title={productUrl}>
                  <span>
                    {" "}
                    |{" "}
                    <span
                      onClick={() => {
                        navigator.clipboard.writeText(productUrl);
                        bottomAlert("Copied to Clipboard", "info");
                      }}
                    >
                      <MdOutlineCopyAll />
                      Product
                    </span>
                  </span>
                </Tooltip>
              }
            </>
          );
        })()}

        {isReceiver && isModeratedChat && (
          <>
            {"  "}
            <Tooltip
              title={item.is_moderated ? "Approved" : "Allow this message."}
            >
              <span
              // onClick={() => !item.is_moderated && this.moderate(item.form_id)}
              >
                {item.is_moderated ? (
                  <FaCheckCircle
                    className={
                      item.is_moderated ? "text-success" : "text-warning"
                    }
                    style={{
                      cursor: item.is_moderated ? "default" : "pointer",
                    }}
                  />
                ) : (
                  <FaExclamationCircle
                    className={
                      item.is_moderated ? "text-success" : "text-warning"
                    }
                    style={{
                      cursor: item.is_moderated ? "default" : "pointer",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          </>
        )}
      </div>
      {/* )} */}

      {/* Modal Component */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <Typography variant="h6"
              style={{ fontSize: "16px", background: " #f8f9fa", padding: "10px 15px", borderBottom: " 1px solid #999" }}>Lead Form</Typography>
            <div style={{ margin: "auto", padding: "15px" }}>
              <table cellPadding="8" style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  {userDetails.data && Object.keys(userDetails.data).length > 0 ? (
                    Object.entries(userDetails.data).map(([key, value]) => (
                      <tr key={key} className="page-title-subheading">
                        <td><b>{key.replace(/_/g, " ")}</b></td>
                        <td>{value}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" style={{ textAlign: "center" }}>
                        No Data Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <Button onClick={handleClose} sx={{ mt: 2 }} class="btn btn-primary">
                Close
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
