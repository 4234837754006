import { useContext, useEffect, useRef } from "react";
import $ from "jquery";
import { FormContext } from "../../../context/FormContext";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
require("formBuilder/dist/form-render.min.js");

export function userData(id) {
    var $fbEditor = document.getElementById(id);
    return $($fbEditor).formRender("userData");
}

export function getParsedUserData(id) {
    var data = userData(id);
    var userdata = {};
    var messages = [];

    data.forEach(element => {
        if (element.userData && element.values) {
            userdata[element.name] = element.userData;
        } else if (element.userData && element.userData[0].trim()) {
            userdata[element.name] = element.userData[0].trim();
        } else if (element.required) {
            messages.push(element.label + " is required.")
        }
    });
    return { "userData": userdata, "messages": messages };
}

export function FormDisplay({ id, formData }) {
    const { setFormData } = useContext(FormContext);
    const formRef = useRef(null);
    const formId = id || "fb-display";

    useEffect(() => {
        if (!formRef.current) return;
        const sanitizedData = JSON.parse(JSON.stringify(formData));
        $(formRef.current).formRender({ formData: sanitizedData });
        setFormData({ id: formId, formJSON: sanitizedData });

        // eslint-disable-next-line
    }, [formData, formId]);

    return <div id={formId} ref={formRef} />;
};