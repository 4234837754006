import React, { useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function AcceptInvitation({notification, takeAction }) { 
    const [open, setOpen] = useState(true);

  return (
    <React.Fragment> 
      <Dialog
        open={open}
        keepMounted
        animationtype="slide"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Organization Invite</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {notification[0].content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        {notification[0].action_links.map((action_link, index) => (
        <Button
          key={`notification-${index}`}
          className={`ml-2 bg-${action_link.color}`}
          onClick={() =>{
            setOpen(false);
            takeAction(notification[0].id, action_link)}}
        >
          {action_link.name}
        </Button>
      ))}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}