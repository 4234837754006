import React from "react";
import ReactDOM from "react-dom";
import MicOffButton from "../../components/Buttons/mic-off";
import MicOnButton from "../../components/Buttons/mic-on";
import CameraOffButton from "../../components/Buttons/camera-off";
import { swapScreen } from "./swapScreen";
import { videoCallTilesToggle } from "./videoCallTilesToggle";
import { config } from "../../../config";
import { alertMessage } from "./alertMessage";
import { getEmailUsernameOrNothing, http } from "./others";
import { postCall } from "../../../utils/methods";
import NetworkIcon from "../../components/Buttons/network-icon";
import { volumeIndicator } from "./volumeIndicator";
import PipModeButton from "../../components/Buttons/pip-mode";
import { pipModeHandler } from "./pipModeHandler";

/* Optimised in accordance with joinChannel.js from agent Dashboard */

export async function joinChannel(AgoraRTC) {
  try {
    // Define remote user events in this order : remote user joins, publishes, unpublishes, leaves
    const setupEventHandlers = () => {
      // Handle user joining the channel
      this.client.on("user-joined", async (user) => {
        let isScreenShared = false;

        const response = await http.get(
          config.url.ACTIONS,
          `/meeting/${this.meeting_data.id}`
        );

        if (
          this.meeting_data.meeting_type === "live_streaming" &&
          !this.meeting_data.moderators.includes(
            response.uid_map[user.uid].user_display_name
          )
        ) {
          return;
        }

        for (let key in this.remoteUsers) {
          if (key in response.uid_map) {
            if(response.uid_map[key].user_display_name.includes("screen-share")) {
              isScreenShared = true;
            }
          } 
        }

        const user_name = getEmailUsernameOrNothing(
          response.uid_map[user.uid]
            ? response.uid_map[user.uid].user_display_name
            : `User-${user.uid}`
        );

        this.remoteUsers[user.uid] = user;
        if (!document.getElementById(`remoteVideo_${user.uid}`)) {
          const remoteVideoContainer = document.createElement("div");
          remoteVideoContainer.id = `remoteVideo_${user.uid}`;
          remoteVideoContainer.className = `video-call-wrapper active-speaker-${user.uid}`;
          remoteVideoContainer.setAttribute(
            "user-name",
            user_name[0].toUpperCase()
          );

          remoteVideoContainer.addEventListener("click", () => {
            swapScreen(remoteVideoContainer);
          });

          // Create and append placeholder elements
          const remoteUserPlaceholderEl = document.createElement("span");
          remoteUserPlaceholderEl.id = "remoteUserPlaceholder";

          const speakingWaveEffectEl = document.createElement("span");
          speakingWaveEffectEl.className = "speaking-wave-effect";

          const userDisplayNameEl = document.createElement("span");
          userDisplayNameEl.className = "user-display-name";
          userDisplayNameEl.innerHTML = user_name[0].toUpperCase();

          remoteUserPlaceholderEl.appendChild(speakingWaveEffectEl);
          remoteUserPlaceholderEl.appendChild(userDisplayNameEl);

          // Create mic status indicator element
          const micStatusIndicator = document.createElement("span");
          micStatusIndicator.className = "mic-status-indicator";
          micStatusIndicator.setAttribute("user-name", user_name);
          ReactDOM.render(<MicOffButton />, micStatusIndicator);

          // Create pip-mode button 
          const pipModeBtn = document.createElement("span");
          pipModeBtn.className = "pip-mode-btn";
          ReactDOM.render(<PipModeButton />, pipModeBtn);
          pipModeBtn.addEventListener("click", (event) => {
            pipModeHandler(event, remoteVideoContainer.querySelector("video"))
          });

          // Create network quality indicator
          const networkQualityIndicator = document.createElement("span");
          networkQualityIndicator.className = "network-quality-info";
          ReactDOM.render(<NetworkIcon />, networkQualityIndicator);

          // Create username element
          const usernameIndicator = document.createElement("span");
          usernameIndicator.className = "username-indicator";
          usernameIndicator.innerHTML = user_name;

          // Create volume indicator element
          const volumeIndicatorEl = document.createElement("span");
          volumeIndicatorEl.className = "volume-indicator";

          const volumeBar1 = document.createElement("span");
          volumeBar1.id = "bar1";
          volumeBar1.className = "bars";

          const volumeBar2 = document.createElement("span");
          volumeBar2.id = "bar2";
          volumeBar2.className = "bars";

          const volumeBar3 = document.createElement("span");
          volumeBar3.id = "bar3";
          volumeBar3.className = "bars";

          volumeIndicatorEl.appendChild(volumeBar1);
          volumeIndicatorEl.appendChild(volumeBar2);
          volumeIndicatorEl.appendChild(volumeBar3);

          // customising the window, if its user's screen share window
          if(user_name.includes("screen-share")) {
            const screenLoaderContainerEl = document.createElement("div");
            screenLoaderContainerEl.style.cssText = "display: flex; flex-direction: column; place-content: center; place-items: center; gap: 20px";

            const screenUserNameEl = document.createElement("span");
            const screenLoaderWrapperEl = document.createElement("div");
            screenLoaderWrapperEl.style.cssText = "position: relative; min-width: 90px; min-height: 90px";

            const screenSpinnerEl = document.createElement("div");
            screenSpinnerEl.style.cssText = "width: 100%; height: 100%; border: 5px solid #000; border-top: 5px solid #fff; border-radius: 50%; animation: screen-share-spinner 1s linear infinite; box-sizing: border-box";

            const screenIconEl = document.createElement("div");
            screenIconEl.innerHTML = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M21 3H3c-1.11 0-2 .89-2 2v14c0 1.11.89 2 2 2h18c1.11 0 2-.89 2-2V5c0-1.11-.89-2-2-2zm0 16.02H3V4.98h18v14.04zM10 12H8l4-4 4 4h-2v4h-4v-4z"></path></svg>`;
            screenIconEl.style.cssText = "position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); font-size: 40px; display: flex; place-content: center; place-items: center";

            screenUserNameEl.innerText = `${user_name.replace("screen-share-","")} (Presenting)`;
            screenUserNameEl.style.textWrap = "nowrap";
            screenUserNameEl.style.fontSize = "18px";

            screenLoaderWrapperEl.appendChild(screenSpinnerEl);
            screenLoaderWrapperEl.appendChild(screenIconEl);
            screenLoaderContainerEl.appendChild(screenLoaderWrapperEl);
            screenLoaderContainerEl.appendChild(screenUserNameEl);
            remoteUserPlaceholderEl.appendChild(screenLoaderContainerEl)

            micStatusIndicator.querySelector("svg").style.display = "none";
            micStatusIndicator.style.right = "4px";
            micStatusIndicator.style.borderRadius = "5px";

            networkQualityIndicator.style.display = "none";
            speakingWaveEffectEl.style.display = "none";
            userDisplayNameEl.style.display = "none";
            volumeIndicatorEl.style.display = "none";
          }

          remoteVideoContainer.appendChild(remoteUserPlaceholderEl);
          remoteVideoContainer.appendChild(micStatusIndicator);
          remoteVideoContainer.appendChild(pipModeBtn);
          remoteVideoContainer.appendChild(networkQualityIndicator);
          remoteVideoContainer.appendChild(usernameIndicator);
          remoteVideoContainer.appendChild(volumeIndicatorEl);

          let remoteVideosContainerEl;

          if (this.meeting_data.meeting_type === "live_streaming") {
            remoteVideosContainerEl = document.getElementById(
              "videoCallMainScreen"
            );
          } else {
            remoteVideosContainerEl = document.getElementById(
              "videoCallVideoTiles"
            );
          }

          remoteVideosContainerEl.appendChild(remoteVideoContainer);

          if(!isScreenShared) {
            swapScreen(remoteVideoContainer); // Make the new user upfront
          }
        }
      });

      // Handle user publishing events
      this.client.on("user-published", async (user, mediaType) => {
        await this.client.subscribe(user, mediaType); // Subscribe to remote tracks

        if (mediaType === "video") {
          const interval = setInterval(() => {
            const container = document.getElementById(`remoteVideo_${user.uid}`);
            if (container) {
              user?.videoTrack?.play(`remoteVideo_${user.uid}`);
              clearInterval(interval); // Stop the interval once the container is found
            }
          }, 500);
        }

        if (mediaType === "audio") {
          if(this.screen_creds?.widget_creds?.uid !== user.uid) {
            user?.audioTrack?.play();
          }

          const interval = setInterval(() => {
            const micStatusIndicator = document.querySelector(
              `#remoteVideo_${user.uid} .mic-status-indicator`
            );

            if (micStatusIndicator) {
              ReactDOM.render(<MicOnButton />, micStatusIndicator);
              clearInterval(interval); // Stop the interval once the micStatusIndicator is found
            }
          }, 500);
        }
      });

      // Handle user unpublishing events (Currently manages only audio unpublishing)
      this.client.on("user-unpublished", async (user, mediaType) => {
        if (mediaType === "audio") {
          const micStatusIndicator = document.querySelector(
            `#remoteVideo_${user.uid} .mic-status-indicator`
          );
          ReactDOM.render(<MicOffButton />, micStatusIndicator);
        }
      });

      // Handle user leaving the channel
      this.client.on("user-left", (user) => {
        const id = user.uid;
        delete this.remoteUsers[id];

        const remoteVideoContainer = document.getElementById(
          `remoteVideo_${user.uid}`
        );

        if (remoteVideoContainer) {
          remoteVideoContainer.remove();

          // Swap to the main screen
          const videoCallVideoTilesChildDivEl = document.querySelectorAll(
            "#videoCallVideoTiles .video-call-wrapper"
          );
          const videoCallMainScreenChildDivEl = document.querySelectorAll(
            "#videoCallMainScreen .video-call-wrapper"
          );
          const videoCallMainScreenEl = document.getElementById(
            "videoCallMainScreen"
          );

          if (
            videoCallVideoTilesChildDivEl.length > 0 &&
            videoCallMainScreenChildDivEl.length === 0
          ) {
            videoCallMainScreenEl.appendChild(
              videoCallVideoTilesChildDivEl[0]
            );
          }
        }
      });
    };

    // Enable dual channel for client
    await this.client.enableDualStream();

    // Handle remote user events
    setupEventHandlers();

    // Join the channel
    await this.client.join(
      this.agoraAppId,
      this.agoraChannelName,
      this.agoraToken,
      this.agoraUID
    );

    // Create Local Tracks
    // using speech_standard instead of music_standard
    // using optimisation mode : motion | Prioritizes video smoothness over quality
    this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
      encoderConfig: "high_quality_stereo",
    });

    if(this.videoInputDevices?.length > 0) {
      if (this.message_type === "video_call") {
        this.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
          facingMode: "user",
          optimizationMode: "motion"
        });
      }
    }

    // Set stream fallback options
    //AUDIO_ONLY: = 2: Subscribe to the low-quality video stream when the network conditions worsen, 
    //and subscribe to audio only when the conditions become too poor to support video transmission.
    this.client.setStreamFallbackOption(this.agoraUID, 2);

    // Publish Local Tracks
    if (this.message_type === "video_call") {
      if(this.localAudioTrack && this.localVideoTrack) {
        this.localVideoTrack.play("localVideoContainer"); // Play the local video track
        // await this.localVideoTrack.setMuted(false); // Set the initial camera state to off
        await this.client.publish([this.localAudioTrack, this.localVideoTrack]); // Publish local tracks to the channel
      } else {
        await this.client.publish([this.localAudioTrack]); // Publish local tracks to the channel
      }
    } else if (this.message_type === "audio_call") {
      if(this.localAudioTrack) {
        await this.client.publish([this.localAudioTrack]); // Publish local tracks to the channel
      }
    }

    // UI Updates
    const localVideoContainerEl = document.getElementById(
      "localVideoContainer"
    );
    localVideoContainerEl.addEventListener("click", () => {
      swapScreen(localVideoContainerEl);
    });

    const toggleVideoCallTiles = document.querySelector(
      ".videoCallVideoTilesToggleBtn"
    );
    toggleVideoCallTiles.addEventListener("click", () => {
      videoCallTilesToggle(toggleVideoCallTiles);
    });

    if (this.meeting_data.meeting_type === "conference") {
      postCall({
        url: config.url.SOURCE,
        path: `/register_user_session_socket/${this.meeting_data.id}/${this.UserContext.user_name}`,
        onSuccess: (response) => {
          // console.log(response.data, "register user session socket")
        },
        onFailure: (error) => {},
      });
    }

    this.setSocketContext((prev) => ({
      ...prev,
      agoraLocalAudioTrack: this.localAudioTrack,
      agoraLocalVideoTrack: this.localVideoTrack,
    }));

    volumeIndicator.call(this, AgoraRTC); // initiate the volume indicator
  } catch (error) {
    if (error.message.includes("NotReadableError")) {
      if (this.localAudioTrack && typeof this.localAudioTrack === "object") {
        await this.client.publish([this.localAudioTrack]); // Publish local tracks to the channel
        await this.localAudioTrack.setMuted(true);
      }

      const toggleCameraEl = document.getElementById("toggleCamera");
      const toggleAudioEl = document.getElementById("toggleAudio");

      this.localVideoTrackMuted = true;
      this.localAudioTrackMuted = true;

      toggleCameraEl.style.background = "#eb271e";
      toggleAudioEl.style.background = "#eb271e";

      ReactDOM.render(<CameraOffButton />, toggleCameraEl);
      ReactDOM.render(<MicOffButton />, toggleAudioEl);
      ReactDOM.render(
        <MicOffButton />,
        document.querySelector(`#localVideoContainer .mic-status-indicator`)
      );

      alertMessage("NotReadableError");
    } else if (error.message.includes("NotAllowedError")) {
      const toggleCameraEl = document.getElementById("toggleCamera");
      const toggleAudioEl = document.getElementById("toggleAudio");

      this.localVideoTrackMuted = true;
      this.localAudioTrackMuted = true;

      toggleCameraEl.style.background = "#eb271e";
      toggleAudioEl.style.background = "#eb271e";

      ReactDOM.render(<CameraOffButton />, toggleCameraEl);
      ReactDOM.render(<MicOffButton />, toggleAudioEl);
      ReactDOM.render(
        <MicOffButton />,
        document.querySelector(`#localVideoContainer .mic-status-indicator`)
      );

      alertMessage("NotAllowedError");
    } else {
      alertMessage("Failed to join the channel~" + error.message);
      console.error("Failed to join the channel:", error.message);
    }
  }
}

