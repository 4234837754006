import React from "react";
import ReactDOM from "react-dom";
import MicOffButton from "../../components/Buttons/mic-off";
import MicOnButton from "../../components/Buttons/mic-on";
import { alertMessage } from "./alertMessage";

export async function toggleAudio(e, AgoraRTC) {
  const micStatusIndicator = document.querySelector(
    `#localVideoContainer .mic-status-indicator`
  );

  if (this.localAudioTrack) {
    if (!this.localAudioTrackMuted) {
      await this.localAudioTrack.setMuted(true);
      this.localAudioTrackMuted = true;
      e.style.background = "#eb271e"; //red
      ReactDOM.render(<MicOffButton />, e);
      ReactDOM.render(<MicOffButton />, micStatusIndicator); // local user's mic indicator set to off
    } else {
      await this.localAudioTrack.setMuted(false);
      this.localAudioTrackMuted = false;
      e.style.background = "#3f3f41";
      ReactDOM.render(<MicOnButton />, e);
      ReactDOM.render(<MicOnButton />, micStatusIndicator); // local user's mic indicator set to on
    }
  } else {
    // alertMessage("NotAllowedError");
    try {
      this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
        encoderConfig: "high_quality_stereo",
      });
      await this.client.publish([this.localAudioTrack]); // Publish local tracks to the channel

      this.localAudioTrackMuted = false;
      e.style.background = "#3f3f41";
      ReactDOM.render(<MicOnButton />, e);
      ReactDOM.render(<MicOnButton />, micStatusIndicator);
    } catch (error) {
      if (error.message.includes("NotReadableError")) {
        alertMessage("NotReadableError");
      } else if (error.message.includes("NotAllowedError")) {
        alertMessage("NotAllowedError");
      } else {
        alertMessage("Mic Error~" + error.message);
        console.error("Mic Error:", error.message);
      }
    }
  }
}
