import React, { useState, useEffect, useRef, Fragment, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  currentTimeInMilliSecs,
  msToDateString,
  msToShortDateString,
  msToTimeString,
} from "../../../../utils/time";
import { getCall, postCall, putCall } from "../../../../utils/methods";
import { config } from "../../../../config";
import { bottomAlert } from "../../../../utils/toastAlerts";
import MessageBoxWrapper from "../../MessageBox/MessageBoxWrapper";
import SendMessage from "../../SendMessageBox/SendMessage";
import { underscoreToSpacedCamel } from "../../../../utils/text";
import LeadConfig from "./LeadConfig/LeadConfig";
import ControlPanel from "../../ContolPanel/ControlPanel";
import { FormDisplay, getParsedUserData } from "../../../Meetings/shared/FormDisplay";
import { ClientContext } from "../../../../context/ClientContext";
import { UserContext } from "../../../../context/UserContext";

import "./lead-details.scss";

export default function LeadDetails() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { Client } = useContext(ClientContext);
  const { User } = useContext(UserContext);

  const [leads, setLeads] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [conversationType] = useState("all");
  const [pageCount, setPageCount] = useState(1);
  const [conversationsCount, setConversationsCount] = useState(0);
  const [clickData, setClickData] = useState();
  const [refreshKey, setRefreshKey] = useState(1);
  const [showControlPanel, setShowControlPanel] = useState(true);
  const [show_lead_transfer_model, set_show_lead_transfer_model] = useState(false);
  const [open, setOpen] = useState(false);


  const messagesTopRef = useRef();
  const messagesStartRef = useRef();
  const messagesEndRef = useRef();

  const pageItemsCount = 10;

  const handleScrollStable = (direction = "bottom") => {
    const options = {
      behavior: "smooth", // Smooth scrolling
      block: "end", // Scroll to the end of the element
    };

    if (direction === "up") {
      messagesStartRef.current &&
        messagesStartRef.current.scrollIntoView(options);
    } else if (direction === "top") {
      messagesTopRef.current && messagesTopRef.current.scrollIntoView(options);
    } else {
      messagesEndRef.current && messagesEndRef.current.scrollIntoView(options);
    }
  };

  const fetchLeads = () => {
    getCall({
      url: config.url.PEOPLE,
      path: `/filter/${searchParams.get("q") ? searchParams.get("q") + "/" : ""
        }1/10`,
      onSuccess: (response) => {
        setLeads(response.data.records);
        getCall({
          url: config.url.TRACKER,
          path: "/cta_by_people_id/" + id,
          onSuccess: (response) => {
            setClickData(response.data);
          },
          onFailure: (error) => {
            console.log(error);
          },
        });
      },
      onFailure: (error) => {
        console.log(error);
      },
    });
  };

  const fetchLeadData = (count) => {
    postCall({
      url: config.url.CONVERSATIONS,
      path: `/people/${id}/${count || pageCount}/${pageItemsCount}`,
      data: {},
      onSuccess: (response) => {
        setLeadData((leadData) => {
          const conversations = leadData;
          response.data.records.forEach((conversation) => {
            conversations.unshift(conversation);
          });
          return conversations;
        });
        setConversationsCount(response.data.count);
        setPageCount(count || 1);

        putCall({
          url: config.url.PEOPLE,
          path: `set_conversations_read/${id}`,
          onSuccess: (response) => { },
          onFailure: (error) => {
            console.log(error);
          },
        });
      },
      onFailure: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    setLeadData([]);    //while rerendering the component then duplicate entries were creaed cuz the previous lead data was not ersaed so I set empty array to avoid duplicate entries.
    fetchLeads();
    fetchLeadData();

    const interval = setInterval(() => {
      if (messagesEndRef) {
        handleScrollStable();
        clearInterval(interval);
      }
    }, 500);

    // eslint-disable-next-line
  }, [refreshKey]);

  const selectedLead = leads?.find((lead) => lead.id === id) || {};


  let lastDate = "";
  let lastTime = "";
  let lastUser = "";
  let lastForm = "";


  // Toggle Control Panel
  const handleControlPanelToggle = () => {
    setShowControlPanel(!showControlPanel);
  };

  const toggle_lead_transfer_model = () => {
    if (!!selectedLead?.is_lead_purchased) {
      updateLeadPurchased(true, selectedLead?.lead_purchased_comment);
    } else {
      set_show_lead_transfer_model(!show_lead_transfer_model);
    }
  };

  const updateLeadPurchased = (lead_purchased, purchased_comment = "") => {
    postCall({
      url: config.url.PEOPLE,
      path: `${selectedLead.id}/purchase_config`,
      data: {
        is_lead_purchased: !lead_purchased,
        lead_purchased_comment: purchased_comment,
      },
      onSuccess: (response) => {
        bottomAlert("Purchase status has been updated", "success");
        setRefreshKey(refreshKey + 1); 
      },
      onFailure: (error) => {
        bottomAlert("Unable to complete your request.", "warning");
      },
    });
  };

  const toggleAgentFeedback = () => {
    setOpen(!open);
  }



  const formResponseHandler = (form_id) => {
    var feedback_response = getParsedUserData(form_id);
    if (feedback_response.messages.length > 0) return;

    postCall({
      url: config.url.FORMS,
      data: {
        people_id: selectedLead.id,
        data: feedback_response.userData,
        relates_to_collection: "",
        collection_id: "",
      },
      onSuccess: (response) => {
        postCall({
          url: config.url.CONVERSATIONS,
          data: {
            message_content: response.data.id,
            meta: [response.data.data],
            message_type: "agent_feedback_form",
            message_from: User.id,
            message_to: selectedLead.id,
            people_id: selectedLead.id,
            unique_field_value: User.user_name,
            form_id: response.data.id,
            source_referrer: window.location.href.includes("&saleassist_referrer=")
              ? window.location.href.split("&saleassist_referrer=")[1]
              : window.location.href,
          },
          onSuccess: (response) => {
            // updateConversation(response.data);
            bottomAlert("Agent Feedback form is submitted.");
            toggleAgentFeedback();
            setRefreshKey(refreshKey + 1); 
          },
          onFailure: (error) => {
            bottomAlert(
              "Form submission failed. " +
              ((error.response && error.response.data.detail) ||
                error.toString()),
              "warning"
            );
          },
        });
      },
      onFailure: (error) => {
        bottomAlert(
          "Form submission failed. " +
          ((error.response && error.response.data.detail) ||
            error.toString()),
          "warning"
        );
      },
    });
  }

  return (
    <div className="lead-conversations-container">
      <section className="lead-conversations-container-header">
        <div className="lead-avatar-wrapper">
          <p className="lead-avatar-text">
            {selectedLead?.unique_field_value?.[0]?.toUpperCase()}
          </p>
          <span
            className="lead-avatar-status"
            style={{
              border: `2px solid ${selectedLead.is_connected &&
                currentTimeInMilliSecs() - selectedLead.last_seen_on < 20000
                ? "#3ac47d"
                : "#fff"
                }`,
              background:
                selectedLead.is_connected &&
                  currentTimeInMilliSecs() - selectedLead.last_seen_on < 20000
                  ? "#fff"
                  : "#ccc",
            }}
          ></span>
        </div>
        <div>
          <p className="lead-unique-field">{
            selectedLead.unique_field_value?.length > 15 ? selectedLead.unique_field_value?.slice(0, 14) + "..." : selectedLead.unique_field_value
          }</p>
        </div>
        <LeadConfig
          refreshKey={refreshKey}
          setRefreshKey={setRefreshKey}
          selectedLead={selectedLead}
          show_lead_transfer_model={show_lead_transfer_model}
          toggle_lead_transfer_model={toggle_lead_transfer_model}
          updateLeadPurchased={updateLeadPurchased}
        />
      </section>
      <div className="lead-conversations-container-body">
        <div className="conversations-wrapper"
          style={{
            height: showControlPanel ? "calc(100% - 135px)" : "calc(100% - 75px)",
            transition: "all 0.5s linear"
          }}
        >
          <div ref={messagesTopRef} />
          <div ref={messagesStartRef} />
          {leadData.length > 0 && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {pageCount * pageItemsCount < conversationsCount ? (
                <Button
                  color="inherit"
                  variant="outlined"
                  style={{ margin: "8px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    fetchLeadData(pageCount + 1);
                    handleScrollStable("top");
                  }}
                >
                  Load more conversations
                </Button>
              ) : (
                <Button variant="contained" disabled style={{ margin: "8px" }}>
                  That's the last one we got.
                </Button>
              )}
            </div>
          )}
          {leadData.length > 0 &&
            leadData
              .filter((conversation) =>
                conversationType === "all"
                  ? true
                  : conversation.message_type === conversationType
              )
              ?.map((item, index) => {
                let isReceiver = (item.message_type === "lead_purchase_status_change" ||
                  item.message_type === "lead_config_status_change" ||
                  item.message_type === "lead_config_type_change")
                  ? false
                  : item.message_from === selectedLead?.id;
                return (
                  <Fragment key={`item-${index}-${item.created_on}`}>
                    {msToDateString(item.created_on) !== lastDate && (
                      <div className="conversation-created-on">
                        <span className="conversation-created-on-text">
                          {msToDateString(item.created_on)}
                        </span>
                        <Divider className="conversation-created-on-divider" />
                      </div>
                    )}
                    <MessageBoxWrapper
                      item={item}
                      isReceiver={isReceiver}
                      hideUser={
                        item.unique_field_value === lastUser &&
                        msToDateString(item.created_on) === lastDate &&
                        item.form_id === lastForm
                      }
                      hideTime={msToTimeString(item.created_on) === lastTime}
                      isModeratedChat={selectedLead?.is_moderated_chat}
                    />
                    {/* eslint-disable-next-line */}
                    {(lastUser = item.unique_field_value) ? "" : ""}
                    {/* eslint-disable-next-line */}
                    {(lastForm = item.form_id) ? "" : ""}
                    {/* eslint-disable-next-line */}
                    {(lastDate = msToDateString(item.created_on)) ? "" : ""}
                    {/* eslint-disable-next-line */}
                    {(lastTime = msToTimeString(item.created_on)) ? "" : ""}
                  </Fragment>
                );
              })}
          <div ref={messagesEndRef} className="lead-last-cta-click">
            {clickData &&
              "Last CTA: " +
              underscoreToSpacedCamel(clickData.clicked_on) +
              " on " +
              underscoreToSpacedCamel(clickData.app_panel) +
              " at " +
              msToShortDateString(clickData.created_on)}
          </div>
        </div>
        {open && (
          <Dialog
            open={open}
            onClose={toggleAgentFeedback}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
            >
              Agent Feedback
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <FormDisplay
                  id="fb-global-display"
                  formData={JSON.parse(Client.feedback_form)}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleAgentFeedback}>Cancel</Button>
              <Button
                onClick={() => formResponseHandler("fb-global-display")}
                autoFocus
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <ControlPanel
          showControlPanel={showControlPanel}
          toggle_lead_transfer_model={toggle_lead_transfer_model}
          selectedLead={selectedLead}
          setLeadData={setLeadData}
          leadData={leadData}
          refreshKey={refreshKey}
          setRefreshKey={setRefreshKey}
          toggleAgentFeedback={toggleAgentFeedback}
        />
        <SendMessage
          id={id}
          leadData={leadData}
          setLeadData={setLeadData}
          selectedLead={selectedLead}
          handleScrollStable={(dir) => {
            handleScrollStable(dir);
          }}
          controlePanelToggle={handleControlPanelToggle}
          showControlPanel={showControlPanel}
        />
      </div>
    </div>
  );
}
