import React, { useState, useContext, useEffect } from 'react'
import "./contolrpanel.scss";
import { useNavigate } from "react-router-dom";
// import AddTaskForChat from "../AddTaskForChat/addTaskForChat";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { CiCalendar } from "react-icons/ci";
import { IoVideocamOutline } from "react-icons/io5";
import { IoChatboxEllipsesOutline } from 'react-icons/io5'
import { FiDollarSign } from "react-icons/fi";
import { config } from "../../../config";
import { postCall } from "../../../utils/methods";
import { bottomAlert } from "../../../utils/toastAlerts";
import { ClientContext } from "../../../context/ClientContext";
import { IoCallOutline } from "react-icons/io5";
import { Tooltip } from "@mui/material";


const ControlPanel = ({ showControlPanel, toggle_lead_transfer_model, selectedLead, setRefreshKey, refreshKey, toggleAgentFeedback }) => {
    const navigate = useNavigate();
    const { Client } = useContext(ClientContext);
    const [leadStatusArray, setLeadStatusArray] = useState([]);
    const [leadTypeArray, setLeadTypeArray] = useState([]);
    const maxWidth__600 = window.matchMedia("(max-width: 600px)").matches;

    const makePhoneCall = () => {
        postCall({
            url: config.url.MEETINGS,
            path: "/outbound_call/" + selectedLead.id,
            onSuccess: () => {
                bottomAlert(
                    "Call initiated. Please receive a call on your phone.",
                    "success"
                );
            },
            onFailure: (error) => {
                bottomAlert(
                    "Unable to place a call. " +
                    ((error.response && error.response.data.detail) ||
                        error.toString()),
                    "warning"
                );
            },
        });
    };

    useEffect(() => {
        setLeadStatusArray(Client.people_config_status);
        setLeadTypeArray(Client.people_config_type);
        // eslint-disable-next-line
    }, []);

    const updateLeadStatus = (lead_status) => {
        postCall({
            url: config.url.PEOPLE,
            path: `${selectedLead.id}/status_config`,
            data: {
                lead_status_name: lead_status.name,
                lead_status_color: lead_status.color_code,
            },
            onSuccess: (response) => {
                bottomAlert("Lead status has been updated", "success");
                setRefreshKey(refreshKey + 1);
            },
            onFailure: (error) => {
                bottomAlert("Unable to complete your request.", "warning");
            },
        });
    };

    const updateLeadType = (lead_type) => {
        postCall({
            url: config.url.PEOPLE,
            path: `${selectedLead.id}/type_config`,
            data: {
                lead_type_name: lead_type.name,
                lead_type_color: lead_type.color_code,
            },
            onSuccess: (response) => {
                bottomAlert("Lead type has been updated", "success");
                setRefreshKey(refreshKey + 1);
            },
            onFailure: (error) => {
                bottomAlert("Unable to complete your request.", "warning");
            },
        });
    };


    return (
        <>
            <div className={`chat-control-panel ${showControlPanel ? "show" : "hide"}`}>
                <ul>
                    <li>
                        <Tooltip title="Video Call">
                            <button onClick={() => {
                                navigate(
                                    `/meetings/new/conference?participant=${selectedLead.unique_field_value}`
                                )
                            }}>
                                <IoVideocamOutline />
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Schedule Meeting"
                            onClick={() => {
                                navigate(
                                    `/meetings/new/schedule?participant=${selectedLead.unique_field_value}`
                                )
                            }}>
                            <button >
                                <CiCalendar />
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Cloud Call" onClick={makePhoneCall}>
                            <button>
                                <IoCallOutline />
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Agent Feedback">
                            <button onClick={toggleAgentFeedback}>
                                <IoChatboxEllipsesOutline />
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Sale Converted">
                            <button onClick={toggle_lead_transfer_model}>
                                <FiDollarSign />
                            </button>
                        </Tooltip>
                    </li> 
                    <li>
                        {/* Lead status dropdown */}
                        <div className="lead-status-type-dropdown"
                            style={{
                                // background: " #fff",
                                borderRadius: "5px",
                                padding: "3px 5px 3px 5px",
                                // border: "1px solid #ddd",
                                // overflow: "hidden"
                            }}

                        >
                            <FormControl sx={{ maxWidth: 120, width: 60 }}>
                                <InputLabel style={{ fontSize: "14px" }} id="lead-status-label">
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    value={{
                                        name: selectedLead?.lead_status_name,
                                        color_code: selectedLead?.lead_status_color,
                                    }}
                                    onChange={(e) => updateLeadStatus(e.target.value)}
                                    input={<OutlinedInput label="Lead Status" />}
                                    renderValue={(selected) => (
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                className={
                                                    !selected.color_code?.includes("#")
                                                        ? `bg-${selected.color_code}`
                                                        : ""
                                                }
                                                style={{
                                                    width: "10px",
                                                    height: "10px",
                                                    borderRadius: "50%",
                                                    backgroundColor: selected.color_code?.includes("#")
                                                        ? selected.color_code
                                                        : "",
                                                }}
                                            ></div>
                                            <div
                                                style={{
                                                    fontSize: "12px",
                                                    marginLeft: "6px",
                                                    display: maxWidth__600 ? "none" : "block",
                                                }}
                                            >
                                                {selected.name}
                                            </div>
                                        </div>
                                    )}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                                // width: 150,
                                                marginLeft: "10px",
                                            },
                                        },
                                    }}
                                    style={{
                                        height: maxWidth__600 ? "auto" : "38px",
                                    }}
                                >
                                    {leadStatusArray?.map((curItem, index, array) => (
                                        <MenuItem
                                            key={index}
                                            value={curItem}
                                            style={{ padding: "6px 12px" }}
                                        >
                                            <span
                                                className={
                                                    !curItem.color_code?.includes("#")
                                                        ? `bg-${curItem.color_code}`
                                                        : ""
                                                }
                                                style={{
                                                    width: "16px",
                                                    height: "16px",
                                                    borderRadius: "4px",
                                                    marginLeft: "-5px",
                                                    marginRight: "6px",
                                                    backgroundColor: curItem.color_code?.includes("#")
                                                        ? curItem.color_code
                                                        : "",
                                                }}
                                            ></span>
                                            <ListItemText primary={curItem.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </li>
                    <li>
                        {/* Lead Type dropdown */}
                        <div className="lead-status-type-dropdown"
                            style={{
                                // background: " #fff",
                                borderRadius: "5px",
                                padding: "3px 5px 3px 5px",
                                // border: "1px solid #ddd",
                                // overflow: "hidden"
                            }}>
                            <FormControl sx={{ maxWidth: 120, width: 60 }}>
                                <InputLabel style={{ fontSize: "14px" }} id="lead-type-label">
                                    Type
                                </InputLabel>
                                <Select
                                    labelId="lead-type-label"
                                    value={{
                                        name: selectedLead?.lead_type_name,
                                        color_code: selectedLead?.lead_type_color,
                                    }}
                                    onChange={(e) => updateLeadType(e.target.value)}
                                    input={<OutlinedInput label="Lead Type" />}
                                    renderValue={(selected) => (
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                className={
                                                    !selected.color_code?.includes("#")
                                                        ? `bg-${selected.color_code}`
                                                        : ""
                                                }
                                                style={{
                                                    width: "10px",
                                                    height: "10px",
                                                    borderRadius: "50%",
                                                    backgroundColor: selected.color_code?.includes("#")
                                                        ? selected.color_code
                                                        : "",
                                                }}
                                            ></div>
                                            <div
                                                style={{
                                                    fontSize: "12px",
                                                    marginLeft: "6px",
                                                    display: maxWidth__600 ? "none" : "block",
                                                }}
                                            >
                                                {selected.name}
                                            </div>
                                        </div>
                                    )}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                                // width: 150,
                                                marginLeft: "10px",
                                            },
                                        },
                                    }}
                                    style={{
                                        height: maxWidth__600 ? "auto" : "38px",
                                    }}
                                >
                                    {leadTypeArray?.map((curItem, index, array) => (
                                        <MenuItem
                                            key={index}
                                            value={curItem}
                                            style={{ padding: "6px 12px" }}
                                        >
                                            <span
                                                className={
                                                    !curItem.color_code?.includes("#")
                                                        ? `bg-${curItem.color_code}`
                                                        : ""
                                                }
                                                style={{
                                                    width: "16px",
                                                    height: "16px",
                                                    borderRadius: "4px",
                                                    marginLeft: "-5px",
                                                    marginRight: "6px",
                                                    backgroundColor: curItem.color_code?.includes("#")
                                                        ? curItem.color_code
                                                        : "",
                                                }}
                                            ></span>
                                            <ListItemText primary={curItem.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </li>
                    {/* <li>
                        <AddTaskForChat selectedChatItemId={selectedChatItemId} />
                    </li> */}
                </ul>
            </div>
        </>
    )
}

export default ControlPanel
