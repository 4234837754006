import React, { useState, useEffect, useContext, } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { config } from "../../config";
import { getCall, postCall } from "../../utils/methods";
import { UserContext } from "../../context/UserContext";
import { bottomAlert } from "../../utils/toastAlerts";
import VideoCallUI from "../../SA-VIdeo-Call/components/Layouts/VideoCallUI";
import DraggableSpeedDial from "../MeetingLite/DraggableSpeedDial";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { IoClose } from "react-icons/io5";
import { renderForm } from "../../utils/form";
import { ThreeDots } from "react-loader-spinner";
import { ClientContext } from "../../context/ClientContext";
import { MdContentCopy } from "react-icons/md";

export default function LiveMeeting({ conference }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { User } = useContext(UserContext);
  const { Client } = useContext(ClientContext);
  const [meetingData, setMeetingData] = useState();
  const [widgetCreds, setWidgetCreds] = useState();
  const [viewerCount, setViewerCount] = useState(0);
  const [callRequest, setCallRequest] = useState({});
  const [activeRequestOpen, setActiveRequestOpen] = useState(false);
  const [renderModalData, setRenderModalData] = useState("active_request");
  const [formErrorText, setFormErrorText] = useState("");
  const [formButton, setFormButton] = useState(false);
  let timer = null;

  const startMeeting = () => {
    getCall({
      url: config.url.MEETINGS,
      path: id,
      onSuccess: (response) => {
        setMeetingData(response.data);
        if (response.data.participants.length === 1) {
          fetchData({ people_id: response.data.peoples[0].id });
        }
        // fetchWidgetSourceData(response.data.created_by_user_id);
        if (response.data.meeting_type === "live_streaming") {
          timer = setInterval(() => {
            fetchStreamStats();
          }, 2000);
        }
        
      

        getCall({
          url: config.url.ACTIONS,
          path: `/get_join_url/${id}/${User?.user_name}`,
          onSuccess: (response) => {
            const room_id = response.data.match(/\/([\w-]+)\?/)[1];
            postCall({
              url: config.url.ACTIONS,
              path: `/meeting/join_channel/${room_id}/${User?.user_name}`,
              onSuccess: (response) => {
                setWidgetCreds({ ...response.data, room_id: room_id });
              },
            });
          },
          onFailure: (error) => {
            bottomAlert("Unable to fetch join url.", "danger");
          },
        });
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to get meeting details. " +
          ((error.response && error.response.data.detail) ||
            error.toString()),
          "warning"
        );
        // navigate("/meetings/lite");
      },
    });

  };
  const fetchData = (request) => {
    getCall({
      url: config.url.PEOPLE,
      path: request.people_id,
      onSuccess: (response) => {
        if (response?.data) {
          setCallRequest(response.data);
        } else {
          setCallRequest({});
        }
      },
    });
  }
  // const fetchCallRequest = () => {
  //   if (meetingData?.participants?.length > 0) {
  //     getCall({
  //       url: config.url.PEOPLE,
  //       path: `/get_people_by_unique_field/${meetingData.participants[0]}`,
  //       onSuccess: (response) => {
  //         if (response?.data) {
  //           setCallRequest(response.data);
  //         } else {
  //           setCallRequest({}); 
  //         }
  //       },
  //       onFailure: (error) => {
  //         bottomAlert(
  //           "Unable to fetch widget source data. " +
  //             ((error.response && error.response.data.detail) || error.toString()),
  //           "error"
  //         );
  //       },
  //     });
  //   }
  // };
  
  // useEffect(() => {
  //   if (meetingData) {
  //     fetchCallRequest();
  //   }
  // }, [meetingData]);

  // const fetchCallRequest = useCallback(() => {
  //   if (meetingData?.participants?.length > 0) {
  //     getCall({
  //       url: config.url.PEOPLE,
  //       path: `/get_people_by_unique_field/${meetingData.participants[0]}`,
  //       onSuccess: (response) => {
  //         console.log(response,"response people id unique field ")
  //         if (response?.data) {
  //           setCallRequest(response.data);
  //         } else {
  //           setCallRequest({});
  //         }
  //       },
  //       onFailure: (error) => {
  //         bottomAlert(
  //           "Unable to fetch widget source data. " +
  //             ((error.response && error.response.data.detail) || error.toString()),
  //           "error"
  //         );
  //       },
  //     });
  //   }
  // }, [meetingData]); // Add dependencies that affect it

  // useEffect(() => {
  //   if (meetingData) {
  //     fetchCallRequest();
  //   }
  // }, [meetingData, fetchCallRequest]);


  const fetchStreamStats = () => {
    getCall({
      url: config.url.MEETINGS,
      path: `/streaming/stats/${id}`,
      onSuccess: (response) => {
        setViewerCount(response.data.viewers);
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to fetch stream stats. " +
          ((error.response && error.response.data.detail) ||
            error.toString()),
          "warning"
        );
      },
    });
  };

  useEffect(() => {
    startMeeting();

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    // eslint-disable-next-line
  }, [id]);
  const extractProductUrl = (url) => {
    try {
      const urlParams = new URLSearchParams(new URL(url).search);
      return decodeURIComponent(urlParams.get("product_url"));
    } catch (error) {
      return null;
    }
  };
  const copyToClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        bottomAlert("Copied to Clipboard", "info");
      })
      .catch((err) => {
        bottomAlert(`Failed to copy: ${err}`, "error");
        console.error("Failed to copy: ", err);
      });
  };
  const callRequestWidgetData = [
    { "Name": "name" },
    { "Mobile Number": "mobile_number" },
    { "Email": "email" },
    { "Last CTA Clicked from Source": "last_cta_clicked_from_source" },
    { "Lead Status": "lead_status_name" },
    { "Lead Type": "lead_type_name" },
  ];

  const gatherFormData = () => {
    const formElements = document.querySelectorAll(".sa-agent-feedback-form input, .sa-agent-feedback-form textarea, .sa-agent-feedback-form select");
    const formData = {};
    formElements.forEach((element) => {
      if (element.name) {
        formData[element.name] = element.value;
      }
    });
    return formData;
  };

  const handleSubmitFeedbackForm = (event) => {
    event.preventDefault(); // Prevents default form submission behavior
    setFormButton(true);

    const formData = gatherFormData();
    postCall({
      url: config.url.FORMS,
      data: {
        people_id: callRequest.id,
        data: formData,
        relates_to_collection: "sources",
        collection_id: callRequest.last_attended_by,
      },
      onSuccess: (response) => {
        postCall({
          url: config.url.CONVERSATIONS,
          // path: this.state.last_source.id + "/conversation",
          data: {
            message_content: response.data.id,
            meta: [response.data.data],
            message_type: "agent_feedback_form",
            message_from: User.id,
            message_to: callRequest.id,
            people_id: callRequest.id,
            unique_field_value: User.user_name,
            form_id: response.data.id,
            source_referrer: window.location.href.includes("&saleassist_referrer=")
              ? window.location.href.split("&saleassist_referrer=")[1]
              : window.location.href,
          },
          onSuccess: (response) => {
            bottomAlert("Agent Feedback form is submitted.");
            setActiveRequestOpen(false);
            setFormButton(false);
          },
          onFailure: (error) => {
            bottomAlert(
              "Form submission failed. " +
              ((error.response && error.response.data.detail) ||
                error.toString()),
              "warning"
            );
          },
        });
      },
      onFailure: (error) => {
        const errorMsg =
          "Form submission failed. " +
          ((error.response && error.response.data.detail) || error.toString());

        bottomAlert(errorMsg, "warning");
        setFormErrorText(errorMsg);
      },
    });
  };
  return (
    <div className="saleassist-meeting-lite">
      {widgetCreds ? (
        <>
          <VideoCallUI
            widget_creds={widgetCreds}
            meeting_type="video_call"
            meetingData={meetingData}
            endCallBack={() => navigate("/meetings/lite")}
            conference={conference}
            liveStream={meetingData.meeting_type === "live_streaming"}
            viewerCount={viewerCount}
          />
          <Dialog
            onClose={() => setActiveRequestOpen(false)}
            open={activeRequestOpen}
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle className="sa-active-request-modal-title">
              {renderModalData.replace("_", " ")}
              <IoClose onClick={() => setActiveRequestOpen(false)} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {/* render the agent feedback form inside modal */}
                {renderModalData === "agent_feedback" && (
                  <form
                    onSubmit={handleSubmitFeedbackForm}
                    className="sa-agent-feedback-form"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: renderForm(
                          JSON.parse(Client.feedback_form)
                        ),
                      }}
                    ></div>

                    <p className="sa-agent-feedback-form-error-text">
                      {formErrorText}
                    </p>

                    <button
                      type="submit"
                      className="sa-agent-feedback-form-submit-button"
                      style={{ cursor: formButton ? "not-allowed" : "pointer" }}
                    >
                      {formButton ? (
                        <ThreeDots
                          visible={true}
                          height="16"
                          width="80"
                          color="#fff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{ placeContent: "center" }}
                          wrapperClass=""
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                )}
                {callRequestWidgetData.map((data, index) => {
  var key = Object.keys(data)[0];
  var value = callRequest[data[key]] || "";
  var productUrl = key === "Product" && extractProductUrl(value);

  var isPill = key === "Lead Status" || key === "Lead Type";
  

  var pillColor =
    key === "Lead Status"
      ? callRequest?.lead_status_color
      : key === "Lead Type"
        ? callRequest?.lead_type_color
        : "";

  return (
    <div key={`${key}-${index}`} className="sa-active-request-modal-widget-content">
      {isPill ? (
        <>
          <span style={{ display: "block", fontWeight: "bold",top:"-5px" }}>{key}</span>
          <span
            className="pill-badge"
            style={{
              backgroundColor: pillColor || "#ccc",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "15px",
              fontSize: "14px",
              fontWeight: "bold",
              display: "inline-block",
              width: "150px",
              textAlign: "center",
             marginLeft:"50px",
             marginTop:"20px"
            }}
          >
            {value}
          </span>
        </>
      ) : (
        <>
          <span>{key}</span>
          <span onClick={() => copyToClipboard(productUrl || value)}>
            {productUrl || value}
            <MdContentCopy />
          </span>
        </>
      )}
    </div>
  );
})}

              </DialogContentText>
            </DialogContent>
          </Dialog>
          {meetingData.participants?.length === 1 && (
            <DraggableSpeedDial
              callRequest={callRequest}
              activeRequestHandle={() => setActiveRequestOpen(true)}
              renderModalDataHandle={(data) => setRenderModalData(data)}
            />
          )}
        </>
      ) : null}
    </div>
  );
}
